import React from 'react';
import { withTranslation } from 'react-i18next';
import {
    TableRow,
    TableCell
} from '@mui/material';
import { prettyDateTime } from '../../../../PrettyDateTime';
import Users from '../Users';
import Actions from './actions/Actions';

function Items({ licenses, ts, orgs, page, orgsPerPage, isPoolLicense, t }) {
    const renderLicenses = (org) => {
        if (org.licenses.length === 0 || licenses.length === 0)
            return null;

        return licenses.filter(license => {
            if (org.licenses.includes(license._id) && license.endTime * 1000 > ts)
                return true;
        }).map(license => (
            <p key={license._id}>
                {
                    `ID: ${license.number} (${license.participants} ${t('licenses.participantsCount')})`
                }
            </p>
        ))
    }

    if (Array.isArray(orgs) && orgs.length > 0)
        return orgs.slice(page * orgsPerPage, page * orgsPerPage + orgsPerPage)
            .map(
                org => {
                    return (
                        <TableRow key={org._id}>
                            <TableCell align="center">{org.name}</TableCell>
                            {
                                isPoolLicense ?
                                    <>
                                        <TableCell align="right">{org.participants}</TableCell>
                                        <TableCell align="right">{org.meetsPerTime}</TableCell>
                                    </>
                                    :
                                    <TableCell align="center">{renderLicenses(org)}</TableCell>
                            }
                            <TableCell align="right">
                                {prettyDateTime(org.exp).split(' ')[0]}
                            </TableCell>
                            <TableCell align="center">
                                <Users users={org.users} />
                            </TableCell>
                            <TableCell align="right">
                                <Actions org={org} />
                            </TableCell>
                        </TableRow>
                    )
                }
            )

    return (
        <TableRow>
            <TableCell colSpan={isPoolLicense ? 6 : 5} className="empty"> {t('orgs.empty')}</TableCell>
        </TableRow>
    )

}

export default withTranslation()(Items);
