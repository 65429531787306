import {
    MEETS_FILTER_CHANGED,

    MEETS_LIST_UPDATED,
    MEETS_LIST_UPDATE,

    MEET_EDIT_TOGGLED,

    MEET_DATE_CHANGED,
    MEET_DURATION_HOURS_CHANGED,
    MEET_DURATION_MINUTES_CHANGED,
    MEET_PEOPLES_CHANGED,
    MEET_DESCRIPTION_CHANGED,
    MEET_LOBBY_TOGGLED,
    MEET_AUTO_LOBBY_TOGGLED,
    MEET_AUTO_OWNER_TOGGLED,
    MEET_START_AUDIO_MUTED_TOGGLED,
    MEET_START_VIDEO_MUTED_TOGGLED,
    MEET_PASS_ENABLED_TOGGLED,
    MEET_PASS_CHANGED,
    MEET_PERIODIC_TOGGLED,
    MEET_DAYS_OF_WEEK_CHANGED,

    MEET_ADD,
    MEET_EDIT,
    MEET_DELETE,

    MEET_GET_MODERATOR_LINK,
    MEET_AUTO_RECORDING
} from './actionTypes';

import {
    meetEditToggled,
    meetsListUpdate,
    meetsListUpdated,
    meetDateChanged,
    meetWebinarRegToggled
} from './actions';

import {
    licensesAvailableLoad
} from '../licenses/actions'

import {
    menuChanged,
    showNotification,
    closeConfirmDialog
} from '../../actions';

import { formFields } from './form/formFields';

import { appFetch } from '../../../app/functions';
import { copyToClipboard } from '../../../app/functions/copyToClipboard';

import { prettyDuration } from '../../../PrettyDuration';

import { nextRoundTime } from '../../../NextRoundTime';


export function meetsMiddleware({ getState, dispatch }) {
    return function (next) {
        return function (action) {
            const state = getState();

            const {
                date,
                durationHours,
                durationMinutes,
                peoples,
                description,
                lobby,
                autoLobby,
                startAudioMuted,
                startVideoMuted,
                pass,
                webinarReg,
                webinarFormFields,
                autoRecording,
            } = state.meets.form;

            const isRooms = state.meets.filter === 'rooms';
            const isWebinars = state.meets.filter === 'webinars';

            switch (action.type) {
                case MEETS_FILTER_CHANGED:
                    if (
                        (state.meets.filter === action.payload.filter && state.main.selectedMenu === 'MeetsFilter')
                        ||
                        state.meets.editId
                    )
                        return;

                    if (isWebinars) {
                        dispatch(meetWebinarRegToggled(false));
                    }

                    const filterCategory = action.payload.filter.indexOf('last_') !== -1 ? 'past' : 'future';
                    action.payload = { ...action.payload, filterCategory, list: [], page: 0 };
                    next(action);

                    if (state.main.selectedMenu === 'MeetsFilter') {
                        if (action.payload.filter === 'rooms') {
                            // Clear add meet form if come to rooms meets from simple meets.
                            // Because user can set periodic before come to rooms.
                            dispatch(meetEditToggled({ editId: null }));
                        } else if (isRooms) {
                            // Clear add meet form if come from rooms meets to simple meets.
                            // Because licenses loaded for next round time, but form date not updated.
                            dispatch(meetEditToggled({ editId: null }));;
                        }

                        dispatch(meetsListUpdate({ filter: action.payload.filter }));
                    } else {
                        // Come from another menu to meets/rooms.
                        // See componentDidMount -> Meets component.
                        dispatch(menuChanged({ selectedMenu: 'MeetsFilter' }));
                    }

                    break;


                case MEETS_LIST_UPDATE:
                    appFetch(
                        state.app.backEnd + '/api/meets/' + action.payload.filter,
                        {
                            credentials: 'include'
                        },
                        (data) => {
                            if (data.status === 'ok' && data.meets && data.currentMeets && data.currentConferences)
                                dispatch(
                                    meetsListUpdated({
                                        meets: data.meets,
                                        currentMeets: data.currentMeets,
                                        currentConferences: data.currentConferences
                                    })
                                );
                        },
                        dispatch,
                        () => {
                            dispatch(showNotification({ severity: 'error', text: 'meetsList.getError' }));
                        },
                        !Boolean(action.payload.background)
                    );

                    return;


                case MEETS_LIST_UPDATED:
                    const page = action.payload.meets / state.meets.meetsPerPage <= state.meets.page && state.meets.page > 0 ? 0 : state.meets.page;

                    action.payload = { ...action.payload, page };

                    break;


                case MEET_EDIT_TOGGLED:
                    const editId = action.payload.editId;
                    const form = { ...formFields };

                    if (editId) {
                        state.meets.list.forEach(meet => {
                            if (meet._id === editId) {
                                const duration = prettyDuration(meet.duration);

                                let date = new Date(meet.date);
                                if (Boolean(meet.periodic)) {
                                    let dateNow = new Date(state.app.settings.ts);
                                    dateNow.setDate(dateNow.getDate() + 1);
                                    date = new Date(
                                        dateNow.getFullYear(),
                                        dateNow.getMonth(),
                                        dateNow.getDate(),
                                        date.getHours(),
                                        date.getMinutes(),
                                        0,
                                        0
                                    );
                                }

                                form.date = date;
                                form.durationHours = duration.hours;
                                form.durationMinutes = duration.minutes;
                                form.peoples = meet.peoples;
                                form.description = meet.description;
                                form.lobby = Boolean(meet.lobby);
                                form.autoLobby = Boolean(meet.autoLobby);
                                form.startAudioMuted = Boolean(meet.startAudioMuted);
                                form.startVideoMuted = Boolean(meet.startVideoMuted);
                                form.autoRecording = Boolean(meet.autoRecording);
                                form.passEnabled = Boolean(meet.pass);
                                form.pass = meet.pass;
                                form.periodic = Boolean(meet.periodic);
                                form.daysOfWeek = meet.daysOfWeek;
                                form.roomId = meet.roomId;
                                form.webinar = meet.webinar;
                                form.webinarReg = meet.webinarReg;
                                form.webinarFormFields = meet.webinarFormFields;
                                form.autoOwner = meet.autoOwner;
                                form.license = meet.license ?
                                    meet.license.endTime * 1000 < state.app.settings.ts ? null : meet.license._id
                                    :
                                    null
                            }
                        })
                    } else {
                        form.date = nextRoundTime(state.app.settings.ts);
                    }

                    action.payload = { ...action.payload, form };
                    next(action);

                    dispatch(
                        licensesAvailableLoad({
                            date: form.date,
                            duration: form.durationHours * 60 + form.durationMinutes,
                            room: isRooms
                        })
                    );

                    break;


                case MEET_DATE_CHANGED:
                    dispatch(
                        licensesAvailableLoad({
                            date: action.payload.date,
                            duration: parseInt(durationHours, 10) * 60 + parseInt(durationMinutes, 10)
                        })
                    );

                    break;


                case MEET_DURATION_HOURS_CHANGED:
                    let minutes = durationMinutes;

                    if (parseInt(action.payload.durationHours, 10) === 0 && parseInt(minutes, 10) === 0)
                        minutes = 15;

                    action.payload = { ...action.payload, durationMinutes: minutes };

                    const durationH = parseInt(action.payload.durationHours, 10) * 60 + parseInt(minutes, 10);

                    dispatch(
                        licensesAvailableLoad({
                            date,
                            duration: durationH
                        })
                    );

                    break;


                case MEET_DURATION_MINUTES_CHANGED:
                    const durationM = parseInt(durationHours, 10) * 60 + parseInt(action.payload.durationMinutes);

                    dispatch(
                        licensesAvailableLoad({
                            date,
                            duration: durationM
                        })
                    );

                    break;


                case MEET_PEOPLES_CHANGED:
                    action.payload = { peoples: action.payload.peoples.replace(/[^\d]/, '') };

                    break;


                case MEET_DESCRIPTION_CHANGED:
                    action.payload = { description: action.payload.description.replace(/\s{2,}/g, ' ').replace(/^\s$/, '') };

                    break;


                case MEET_LOBBY_TOGGLED:
                    action.payload = { lobby: !state.meets.form.lobby, autoLobby: false, passEnabled: false, pass: '' };

                    break;

                case MEET_AUTO_LOBBY_TOGGLED:
                    action.payload = { autoLobby: !state.meets.form.autoLobby, lobby: false, passEnabled: false, pass: '' };

                    break;

                case MEET_AUTO_OWNER_TOGGLED:
                    action.payload = { autoOwner: !state.meets.form.autoOwner };

                    break;

                case MEET_START_AUDIO_MUTED_TOGGLED:
                    action.payload = { startAudioMuted: !state.meets.form.startAudioMuted };

                    break;
                case MEET_AUTO_RECORDING:
                    action.payload = { autoRecording: !state.meets.form.autoRecording };

                    break;

                case MEET_START_VIDEO_MUTED_TOGGLED:
                    action.payload = { startVideoMuted: !state.meets.form.startVideoMuted };

                    break;


                case MEET_PASS_ENABLED_TOGGLED:
                    action.payload = { passEnabled: !state.meets.form.passEnabled, pass: '', lobby: false, autoLobby: false };

                    break;


                case MEET_PASS_CHANGED:
                    action.payload = { pass: action.payload.pass.replace(/\s/, '').replace(/[а-я]/i, '') };

                    break;


                case MEET_PERIODIC_TOGGLED:
                    action.payload = { periodic: !state.meets.form.periodic };
                    next(action);

                    let selectedDate = state.meets.form.date;
                    if (action.payload.periodic) {
                        selectedDate.setDate(selectedDate.getDate() + 1);
                        dispatch(meetDateChanged({ date: selectedDate }));
                    } else {
                        selectedDate.setDate(selectedDate.getDate() - 1);
                        dispatch(meetDateChanged({ date: selectedDate }));
                    }

                    break;


                case MEET_DAYS_OF_WEEK_CHANGED:
                    let daysOfWeek = state.meets.form.daysOfWeek;
                    daysOfWeek[action.payload.day] = !daysOfWeek[action.payload.day];

                    action.payload = { daysOfWeek };

                    next(action);

                    dispatch(
                        licensesAvailableLoad({
                            date: state.meets.form.date,
                            duration: parseInt(state.meets.form.durationHours, 10) * 60 + parseInt(state.meets.form.durationMinutes, 10),
                        })
                    );

                    break;


                case MEET_ADD:
                    appFetch(
                        state.app.backEnd + '/api/meets',
                        {
                            credentials: 'include',
                            method: 'POST',
                            headers: { 'Content-Type': 'application/json' },
                            body: JSON.stringify({
                                meetDate: Date.parse(date),
                                meetDuration: parseInt(durationHours, 10) * 60 + parseInt(durationMinutes, 10),
                                meetPeoples: peoples,
                                licenseId: state.meets.form.license,
                                meetDescription: description,
                                meetLobby: lobby,
                                meetAutoLobby: autoLobby,
                                meetStartAudioMuted: startAudioMuted,
                                meetStartVideoMuted: startVideoMuted,
                                meetAutoRecording: autoRecording,
                                meetPass: pass,
                                meetPeriodic: state.meets.form.periodic,
                                meetDaysOfWeek: state.meets.form.daysOfWeek,
                                meetRoom: Boolean(isRooms),
                                meetRoomId: state.meets.form.roomId,
                                meetWebinar: Boolean(isWebinars),
                                meetWebinarReg: Boolean(webinarReg),
                                meetWebinarFormFields: webinarFormFields,
                                meetAutoOwner: state.meets.form.autoOwner
                            })
                        },
                        (data) => {
                            if (data.status === 'ok') {
                                dispatch(
                                    showNotification(
                                        {
                                            severity: 'success',
                                            text: isRooms ? 'addMeet.addedRoomSucceeded' : 'addMeet.addedSucceeded'
                                        }
                                    )
                                );
                                dispatch(meetsListUpdate({ filter: state.meets.filter }));
                                // Clear add meet form after successfully adding
                                dispatch(meetEditToggled({ editId: null }));
                            } else if (data.status === 'limit' && data.limit) {
                                dispatch(showNotification({ severity: 'error', text: 'addMeet.confLimit', textAttr: { limit: data.limit } }));
                                dispatch(meetsListUpdate({ filter: state.meets.filter }));
                            } else if (data.status === 'error')
                                dispatch(showNotification({ severity: 'error', text: 'addMeet.addFailed' }));
                            else if (data.status === 'peoples_incorrect' || data.status === 'license_already_used') {
                                dispatch(showNotification(
                                    { severity: 'error', text: 'addMeet.' + data.status }));
                                dispatch(
                                    licensesAvailableLoad({
                                        date: state.meets.form.date,
                                        duration: parseInt(state.meets.form.durationHours, 10) * 60 + parseInt(state.meets.form.durationMinutes, 10),
                                        room: isRooms,
                                        forceSelect: true
                                    })
                                );
                                dispatch(meetsListUpdate({ filter: state.meets.filter }));
                            }
                            else
                                dispatch(showNotification({ severity: 'error', text: 'addMeet.' + data.status }));
                        },
                        dispatch,
                        () => {
                            dispatch(showNotification({ severity: 'error', text: 'addMeet.addFailed' }));
                        }
                    );

                    return;


                case MEET_EDIT:
                    appFetch(
                        state.app.backEnd + '/api/meets',
                        {
                            credentials: 'include',
                            method: 'PUT',
                            headers: { 'Content-Type': 'application/json' },
                            body: JSON.stringify({
                                meetId: state.meets.editId,
                                meetDate: Date.parse(date),
                                meetDuration: parseInt(durationHours, 10) * 60 + parseInt(durationMinutes, 10),
                                meetPeoples: peoples,
                                licenseId: state.meets.form.license,
                                meetDescription: description,
                                meetLobby: lobby,
                                meetAutoLobby: autoLobby,
                                meetStartAudioMuted: startAudioMuted,
                                meetStartVideoMuted: startVideoMuted,
                                meetAutoRecording: autoRecording,
                                meetPass: pass,
                                meetPeriodic: state.meets.form.periodic,
                                meetDaysOfWeek: state.meets.form.daysOfWeek,
                                meetRoom: Boolean(isRooms),
                                meetRoomId: state.meets.form.roomId,
                                meetWebinarReg: Boolean(webinarReg),
                                meetWebinarFormFields: state.meets.form.webinarFormFields,
                                meetAutoOwner: state.meets.form.autoOwner
                            })
                        },
                        (data) => {
                            if (data.status === 'ok')
                                dispatch(showNotification({ severity: 'success', text: 'editMeet.savedSucceeded' }));
                            else if (data.status === 'current')
                                dispatch(showNotification({ severity: 'warning', text: 'editMeet.savedCurrentSucceeded' }));
                            else if (data.status === 'limit')
                                dispatch(showNotification({ severity: 'error', text: 'editMeet.confLimit' }));
                            else if (data.status === 'error')
                                dispatch(showNotification({ severity: 'error', text: 'editMeet.saveFailed' }));
                            else if (data.status === 'peoples_incorrect' || data.status === 'license_already_used') {
                                dispatch(showNotification({ severity: 'error', text: 'editMeet.' + data.status }));
                                dispatch(
                                    licensesAvailableLoad({
                                        date: state.meets.form.date,
                                        duration: parseInt(state.meets.form.durationHours, 10) * 60 + parseInt(state.meets.form.durationMinutes, 10),
                                        room: isRooms,
                                        forceSelect: true
                                    })
                                );
                            } else if (data.status === 'peoples_less_current') {
                                dispatch(showNotification({ severity: 'error', text: 'editMeet.' + data.status }));
                                dispatch(meetsListUpdate({ filter: state.meets.filter }));
                            } else
                                dispatch(showNotification({ severity: 'error', text: 'editMeet.' + data.status }));
                        },
                        dispatch,
                        () => {
                            dispatch(showNotification({ severity: 'error', text: 'editMeet.saveFailed' }));
                        }
                    );

                    return;


                case MEET_DELETE:
                    appFetch(
                        state.app.backEnd + '/api/meets',
                        {
                            credentials: 'include',
                            method: 'DELETE',
                            headers: {
                                'Content-Type': 'application/json'
                            },
                            body: JSON.stringify({ meetId: action.payload.id })
                        },
                        (data) => {
                            if (data.status === 'ok') {
                                dispatch(
                                    showNotification(
                                        {
                                            severity: 'success',
                                            text: isRooms ? 'meetsList.roomDeleted' : 'meetsList.deleted'
                                        }
                                    )
                                );
                                dispatch(closeConfirmDialog());
                                dispatch(meetsListUpdate({ filter: state.meets.filter }));
                                dispatch(
                                    licensesAvailableLoad({
                                        date: state.meets.form.date,
                                        duration: parseInt(state.meets.form.durationHours, 10) * 60 + parseInt(state.meets.form.durationMinutes, 10),
                                        room: isRooms
                                    })
                                );
                            } else {
                                dispatch(showNotification({ severity: 'error', text: 'meetsList.error' }));
                            }
                        },
                        dispatch,
                        () => {
                            dispatch(showNotification({ severity: 'error', text: 'meetsList.error' }));
                        }
                    )

                    return;


                case MEET_GET_MODERATOR_LINK:
                    appFetch(
                        state.app.backEnd + '/api/meets/get_moderator_link',
                        {
                            credentials: 'include',
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json'
                            },
                            body: JSON.stringify({ meetId: action.meetId })
                        },
                        (data) => {
                            if (data.status === 'ok' && data.moderatorLink) {
                                const meetURL =
                                    `${action.meetURL}/`
                                    + (
                                        action.roomId
                                            ? `${action.roomId}/${data.moderatorLink.split('_')[0]}`
                                            : data.moderatorLink
                                    );
                                if (action.play) {
                                    let a = document.createElement('a');
                                    a.target = '_blank';
                                    a.href = meetURL;
                                    a.click();
                                    return;
                                }
                                copyToClipboard(meetURL)
                                    .then(() => {
                                        dispatch(showNotification({ severity: 'success', text: 'meetsList.linkCopied' }));
                                    })
                                    .catch(error => {
                                        console.error(error);
                                        dispatch(showNotification({ severity: 'error', text: 'meetsList.linkCopyFailed' }));
                                    })
                            } else {
                                dispatch(showNotification({ severity: 'error', text: 'meetsList.error' }));
                            }
                        },
                        dispatch,
                        () => {
                            dispatch(showNotification({ severity: 'error', text: 'meetsList.error' }));
                        }
                    );
                    return;
            }

            return next(action);
        }
    }
}
