import {
    MEETS_FILTER_DATE_RANGE_CHANGED,
    MEETS_FILTER_ITEM_SELECTED,
    MEETS_FILTER_OPTION_TOGGLED,
    MEETS_FILTER_CLEAR
} from './actionTypes';


const meetsFilter = {
    dateRange: [ null, null ],
    startDate: 0,
    endDate: 0,
    days: [],
    orgs: [],
    users: [],
    types: [],
    recordings: false
};


export function meetsFilterReducer( state = meetsFilter, action ) {
    switch( action.type ) {
        case MEETS_FILTER_DATE_RANGE_CHANGED:
            return {
                ...state,
                dateRange: action.dates,
                startDate: action.startDate,
                endDate: action.endDate,
                days: action.days
            };

        case MEETS_FILTER_ITEM_SELECTED:
            return { ...state, [ action.field ]: action.value };

        case MEETS_FILTER_OPTION_TOGGLED:
            return { ...state, [ action.option ]: !state[ action.option ] };

        case MEETS_FILTER_CLEAR:
            return meetsFilter;

        default:
            return state;
    }
}
