import React from 'react';
import { connect } from 'react-redux';
import {
    orgsFormFieldChanged,
    orgsFormEdit
} from '../actions';
import { withTranslation } from 'react-i18next';
import {
    TextField,
    FormControlLabel,
    Switch,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Checkbox,
    ListItemText
} from '@mui/material';
import InputMask from 'react-input-mask';


class Form extends React.Component {
    componentDidMount() {
        const { org, dispatch } = this.props;
        if( org && org._id )
            dispatch( orgsFormEdit({ org: { ...org } }) );
    }


    renderPoolFields() {
        const { _form, _participantsAvailable, org, dispatch, t } = this.props;

        const participantsAvailable = org && org.participants ? org.participants + _participantsAvailable : _participantsAvailable;

        return (
            <>
                <TextField
                    variant="outlined"
                    size="small"
                    margin="dense"
                    fullWidth
                    required
                    error={ _form.participants > participantsAvailable }
                    helperText={ t('orgs.participantsAvailable', { participantsAvailable }) }
                    label={ t('orgs.participants') }
                    name="participants"
                    value={ _form.participants }
                    onChange={ event => dispatch( orgsFormFieldChanged({ field: event.target.name, value: event.target.value }) ) }
                />

                <TextField
                    variant="outlined"
                    size="small"
                    margin="dense"
                    fullWidth
                    required
                    label={ t('orgs.meetsPerTime') }
                    name="meetsPerTime"
                    value={ _form.meetsPerTime }
                    onChange={ event => dispatch( orgsFormFieldChanged({ field: event.target.name, value: event.target.value }) ) }
                />
            </>
        )
    }


    renderRoomFields() {
        const { org, _form, _licenses, _ts, dispatch, t } = this.props;

        const licenses = _licenses.filter(license => {
            if( (!license.used || (org && org._id && org.licenses.includes(license._id))) && license.endTime * 1000 > _ts )
                return license;
        });

        return(
            <FormControl
                variant="outlined"
                size="small"
                margin="dense"
                fullWidth
            >
                <InputLabel id="select-licenses">
                    { licenses.length > 0 ? t('orgs.licenses') : t('orgs.noLicenses') }
                </InputLabel>
                <Select
                    label={ t('orgs.licenses') }
                    labelId="select-licenses"
                    multiple
                    renderValue={
                        ( value ) => (
                            licenses.filter(license => {
                                if( value.includes(license._id) )
                                    return true;
                            }).map(license => (
                                `ID: ${license.number} (${license.participants} ${t('licenses.participantsCount')})`
                            )).join(', ')
                        )
                    }
                    name="licenses"
                    value={ _form.licenses }
                    onChange={ event => dispatch( orgsFormFieldChanged({ field: event.target.name, value: event.target.value }) ) }
                    disabled={ licenses.length === 0 }
                >
                    {
                        licenses.map(license => (
                            <MenuItem
                                key={ license._id }
                                value={ license._id }
                            >
                                <Checkbox checked={ _form.licenses.includes(license._id) } />
                                <ListItemText
                                    primary={
                                        `ID: ${license.number} (${license.participants} ${t('licenses.participantsCount')})`
                                    }
                                />
                            </MenuItem>
                        ))
                    }
                </Select>
            </FormControl>
        )
    }


    render() {
        const { _form, _isPoolLicense, org, dispatch, t } = this.props;

        return (
            <form onSubmit={ () => {} }>
                <TextField
                    variant="outlined"
                    size="small"
                    margin="dense"
                    fullWidth
                    required
                    disabled={ org && org.name === 'default' }
                    label={ t('orgs.name') }
                    name="name"
                    value={ _form.name }
                    onChange={ event => dispatch( orgsFormFieldChanged({ field: event.target.name, value: event.target.value }) ) }
                />

                {
                    _isPoolLicense ?
                        this.renderPoolFields()
                    :
                        this.renderRoomFields()
                }

                <InputMask
                    mask="99.99.9999"
                    disabled={ org && org.name === 'default' }
                    value={ _form.exp }
                    onChange={ event => dispatch( orgsFormFieldChanged({ field: event.target.name, value: event.target.value }) ) }
                >
                    {
                        () =>
                            <TextField
                                variant="outlined"
                                size="small"
                                margin="dense"
                                fullWidth
                                required
                                disabled={ org && org.name === 'default' }
                                helperText={ t('orgs.expHelperText') }
                                label={ t('orgs.exp') }
                                name="exp"
                            />
                    }
                </InputMask>

                <FormControlLabel
                    label={ t('orgs.dedicatedJvb') }
                    labelPlacement="end"
                    control={
                        <Switch
                            name="dedicatedJvb"
                            checked={ _form.dedicatedJvb }
                            onChange={ event => dispatch( orgsFormFieldChanged({ field: event.target.name, value: !_form.dedicatedJvb }) ) }
                            color="primary"
                        />
                    }
                />
            </form>
        )
    }
}


function mapStateToProps(state) {
    return {
        _form: state.users.orgsForm,
        _participantsAvailable: state.licenses.participantsAvailable,
        _isPoolLicense: state.app.settings.isPoolLicense,
        _licenses: state.licenses.licenses,
        _ts: state.app.settings.ts
    }
}


export default connect(mapStateToProps)(withTranslation()(Form));
