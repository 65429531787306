import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';


class Limits extends React.Component {
    render() {
        const { _meet, t } = this.props;
        const { limitStatus, limit } = _meet;

        if( limitStatus ) {
            return (
                <p className="limits">{ t(`meet.${ limitStatus }`, { limit }) }</p>
            )
        }

        return null;
    }
}


function mapStateToProps( state ) {
    return {
        _meet: state.meet
    }
}


export default connect( mapStateToProps )( withTranslation()( Limits ) );
