export const MEETS_FILTER_CHANGED = 'MEETS_FILTER_CHANGED';

export const MEETS_LIST_UPDATE = 'MEETS_LIST_UPDATE';
export const MEETS_LIST_UPDATED = 'MEETS_LIST_UPDATED';

export const MEETS_PAGE_CHANGED = 'MEETS_PAGE_CHANGED';
export const MEETS_PER_PAGE_CHANGED = 'MEETS_PER_PAGE_CHANGED';

export const MEET_EDIT_TOGGLED = 'MEET_EDIT_TOGGLED';

export const MEET_DATE_CHANGED = 'MEET_DATE_CHANGED';
export const MEET_DURATION_HOURS_CHANGED = 'MEET_DURATION_HOURS_CHANGED';
export const MEET_DURATION_MINUTES_CHANGED = 'MEET_DURATION_MINUTES_CHANGED';
export const MEET_PEOPLES_CHANGED = 'MEET_PEOPLES_CHANGED';
export const MEETS_FORM_FIELD_CHANGED = 'MEETS_FROM_FIELD_CHANGED';
export const MEET_DESCRIPTION_CHANGED = 'MEET_DESCRIPTION_CHANGED';
export const MEET_LOBBY_TOGGLED = 'MEET_LOBBY_TOGGLED';
export const MEET_AUTO_LOBBY_TOGGLED = 'MEET_AUTO_LOBBY_TOGGLED';
export const MEET_AUTO_OWNER_TOGGLED = 'MEET_AUTO_OWNER_TOGGLED';
export const MEET_START_AUDIO_MUTED_TOGGLED = 'MEET_START_AUDIO_MUTED_TOGGLED';
export const MEET_START_VIDEO_MUTED_TOGGLED = 'MEET_START_VIDEO_MUTED_TOGGLED';
export const MEET_PASS_ENABLED_TOGGLED = 'MEET_PASS_ENABLED_TOGGLED';
export const MEET_PASS_CHANGED = 'MEET_PASS_CHANGED';
export const MEET_PERIODIC_TOGGLED = 'MEET_PERIODIC_TOGGLED';
export const MEET_DAYS_OF_WEEK_CHANGED = 'MEET_DAYS_OF_WEEK_CHANGED';
export const MEET_WEBINAR_REG_TOGGLED = 'MEET_WEBINAR_REG_TOGGLED';
export const MEET_WEBINAR_FORM_FIELD_TOGGLED = 'MEET_WEBINAR_FORM_FIELD_TOGGLED';

export const MEET_ADD = 'MEET_ADD';
export const MEET_EDIT = 'MEET_EDIT';
export const MEET_DELETE = 'MEET_DELETE';

export const MEET_GET_MODERATOR_LINK = 'MEET_GET_MODERATOR_LINK';
export const MEET_AUTO_RECORDING = "MEET_AUTO_RECORDING"