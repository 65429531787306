import React from 'react';
import { connect } from 'react-redux';
import meetStatuses from './meetStatuses';
import MeetPage from './MeetPage';
import Iframe from './Iframe';
import Info from './Info';
import Notification from '../main/Notification';


class Meet extends React.Component {
    render() {
        const { _meet, _locationHost, webinar } = this.props;

        return (
            <>
            { _meet.status === meetStatuses.STARTED ?
                <Iframe
                    info={ _meet.info }
                    webinar={ webinar }
                    displayName={ _meet.webinarRegForm.fio }
                    locationHost={ _locationHost } />
                :
                <MeetPage>
                    <Info meetId={ this.props.match.params.meetId } webinar={ webinar } />
                    <Notification />
                </MeetPage>
            }
            <audio
                id="autoPlayHack"
                src="/silence.mp3"
                controls={ false }
                autoPlay={ false }
                muted={ false }
                style={{ display: 'none' }}></audio>
            </>
        )
    }
}


function mapStateToProps(state) {
    return {
        _meet: state.meet,
        _locationHost: state.app.locationHost
    }
}


export default connect(mapStateToProps)(Meet);
