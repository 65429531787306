import React from 'react';
import { meetEditToggled } from '../../actions';
import { Edit as EditIcon } from '@mui/icons-material';
import Action from '../../../../Action';
import { useDispatch } from 'react-redux';

function Edit({ meet }) {
    const dispatch = useDispatch();
    return (
        <Action
            showIfSecretary={true}
            title={'meetsList.edit'}
            icon={<EditIcon />}
            onClickHandle={
                () => {
                    dispatch(meetEditToggled({ editId: meet._id }));
                }
            }
        >
        </Action>
    )

}

export default Edit;
