import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import {
    Box,
    Divider,
    FormControl,
    FormGroup,
    Typography,
    TextField,
    Button
} from '@mui/material';
import InputMask from 'react-input-mask';
import {
    meetWebinarRegFormFieldChanged,
    meetWebinarReg
} from './actions';
import meetStatuses from './meetStatuses';

class WebinarRegForm extends React.Component {
    renderForm() {
        const { _info, _form, _settings, dispatch, t } = this.props;
        const { webinarFormFields, webinarReg } = _info;

        return(
            <>
                <Typography paragraph={ true } variant="h6">{t('meetForm.webinarReg')}</Typography>

                <FormControl component="fieldset" variant="standard">
                    <FormGroup>
                        { webinarFormFields.fio ?
                            <TextField
                                variant="filled"
                                size="small"
                                margin="dense"
                                fullWidth
                                label={ t('meetForm.webinar.reg.fio') }
                                name="fio"
                                value={ _form.fio }
                                onChange={
                                    event => dispatch(meetWebinarRegFormFieldChanged(event.target.name, event.target.value))
                                }
                            />
                            :
                            null
                        }
                        { webinarFormFields.phone ?
                            <InputMask
                                type="text"
                                id="phone"
                                name="phone"
                                autoComplete="off"
                                inputMode="numeric"
                                mask="+7 (999) 999-99-99"
                                value={ _form.phone }
                                onChange={
                                    event => dispatch(meetWebinarRegFormFieldChanged(event.target.name, event.target.value))
                                }
                            >
                            {
                                () =>
                                    <TextField
                                        variant="filled"
                                        size="small"
                                        margin="dense"
                                        fullWidth
                                        label={ t('meetForm.webinar.reg.phone') }
                                        name="phone"
                                    />
                            }
                            </InputMask>
                            :
                            null
                        }
                        { webinarFormFields.email ?
                            <TextField
                                variant="filled"
                                size="small"
                                margin="dense"
                                fullWidth
                                label={ t('meetForm.webinar.reg.email') }
                                name="email"
                                value={ _form.email }
                                onChange={
                                    event => dispatch(meetWebinarRegFormFieldChanged(event.target.name, event.target.value))
                                }
                            />
                            :
                            null
                        }
                        { webinarFormFields.city ?
                            <TextField
                                variant="filled"
                                size="small"
                                margin="dense"
                                fullWidth
                                label={ t('meetForm.webinar.reg.city') }
                                name="city"
                                value={ _form.city }
                                onChange={
                                    event => dispatch(meetWebinarRegFormFieldChanged(event.target.name, event.target.value))
                                }
                            />
                            :
                            null
                        }
                        { webinarFormFields.address ?
                            <TextField
                                variant="filled"
                                size="small"
                                margin="dense"
                                fullWidth
                                label={ t('meetForm.webinar.reg.address') }
                                name="address"
                                value={ _form.address }
                                onChange={
                                    event => dispatch(meetWebinarRegFormFieldChanged(event.target.name, event.target.value))
                                }
                            />
                            :
                            null
                        }
                        { webinarFormFields.org ?
                            <TextField
                                variant="filled"
                                size="small"
                                margin="dense"
                                fullWidth
                                label={ t('meetForm.webinar.reg.org') }
                                name="org"
                                value={ _form.org }
                                onChange={
                                    event => dispatch(meetWebinarRegFormFieldChanged(event.target.name, event.target.value))
                                }
                            />
                            :
                            null
                        }
                        { webinarFormFields.jobTitle ?
                            <TextField
                                variant="filled"
                                size="small"
                                margin="dense"
                                fullWidth
                                label={ t('meetForm.webinar.reg.jobTitle') }
                                name="jobTitle"
                                value={ _form.jobTitle }
                                onChange={
                                    event => dispatch(meetWebinarRegFormFieldChanged(event.target.name, event.target.value))
                                }
                            />
                            :
                            null
                        }
                    </FormGroup>
                </FormControl>
                <div style={{ margin: '10px' }}>
                    <Button
                        variant="contained"
                        onClick={() => { dispatch(meetWebinarReg(webinarReg)) }}
                    >
                        { webinarReg ?
                            t('meetForm.webinar.reg.reg')
                            :
                            t('meetForm.webinar.reg.enter')
                        }
                    </Button>
                </div>
                <Typography paragraph={ true } variant="caption">
                    {t('meetForm.webinar.reg.agree.part1')}
                    {` "${t('meetForm.webinar.reg.reg')}", `}
                    <br />
                    {` ${t('meetForm.webinar.reg.agree.part2')} `}
                    <a href={ _settings.policyURL } target="_blank">{t('meetForm.webinar.reg.agree.part3')}</a>
                </Typography>
            </>
        )
    }

    renderRegisteredMessage() {
        const { t } = this.props;

        return (
            <>
                <Typography paragraph={ true } variant="h6">{t('meetForm.webinar.reg.succeeded.title')}</Typography>
                <Typography paragraph={ true } variant="body1">{t('meetForm.webinar.reg.succeeded.text')}</Typography>
            </>
        )
    }

    render() {
        const { _status } = this.props;

        return (
            <Box textAlign="center">
                <Divider variant="middle" />
                <br />

                { _status === meetStatuses.WEBINAR_REG_SUCCEEDED ?
                    this.renderRegisteredMessage()
                    :
                    this.renderForm()
                }
            </Box>
        )
    }
}

function mapStateToProps(state) {
    return {
        _status: state.meet.status,
        _info: state.meet.info,
        _form: state.meet.webinarRegForm,
        _settings: state.app.settings
    }
}

export default connect(mapStateToProps)(withTranslation()(WebinarRegForm));
