import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { menuChanged } from '../actions';
import { withTranslation } from 'react-i18next';
import {
    ListItem,
    ListItemIcon,
    ListItemText
} from '@mui/material';

function AdminMenuItem({ name, icon, text, t }) {
    const { _selectedMenu } = useSelector(state => {
        return {
            _selectedMenu: state.main.selectedMenu
        }
    })
    const dispatch = useDispatch()
    return (
        <ListItem
            button
            selected={_selectedMenu === name}
            onClick={
                () => {
                    dispatch(menuChanged({ selectedMenu: name }));
                }
            }
        >
            <ListItemIcon>
                {icon}
            </ListItemIcon>

            <ListItemText primary={t(text)} />
        </ListItem>
    )
}

export default (withTranslation()(AdminMenuItem));
