import React from 'react';

import {
    isBrowserOk
} from './functions';

import UnsupportedBrowsers from './UnsupportedBrowsers';
import WarningBrowsers from './WarningBrowsers';


class Message extends React.Component {
    render() {
        if( !isBrowserOk() )
            return <UnsupportedBrowsers />

        return(
            <>
                { this.props.children }
                <WarningBrowsers />
            </>
        )
    }
}


export default Message;
