import React from 'react';
import { connect, useSelector } from 'react-redux';
import MeetPage from '../meet/MeetPage';
import Message from '../meet/Message';
import JoinRoom from './JoinRoom';
import Iframe from '../meet/Iframe';
import meetStatuses from '../meet/meetStatuses';



function Room(props) {
    const { _room, _locationHost } = useSelector(state => {
        return {
            _room: state.room,
            _locationHost: state.app.locationHost
        }
    });

    const { status, info } = _room;
    let roomId = '';
    let hash = '';
    if (props.match && props.match.params) {
        roomId = props.match.params.roomId;
        hash = props.match.params.hash;
    }

    if (status === meetStatuses.STARTED)
        return <Iframe info={info} locationHost={_locationHost} />

    return (
        <MeetPage>
            <Message>
                <JoinRoom roomId={roomId} hash={hash} />
            </Message>
        </MeetPage>
    )

}

function mapStateToProps(state) {
    return {
        _room: state.room,
        _locationHost: state.app.locationHost
    }
}


export default connect(mapStateToProps)(Room);
