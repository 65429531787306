import {
    MENU_CHANGED,
    SHOW_NOTIFICATION,
    SHOW_CONFIRM_DIALOG,
    SHOW_MODAL,
    CLOSE_MODAL
} from './actionTypes';

import {
    meetEditToggled
} from './content/meets/actions';

export function mainMiddleware({ getState, dispatch }) {
    return function(next) {
        return function(action) {
            const state = getState();

            switch(action.type) {
                case MENU_CHANGED:
                    if(state.main.selectedMenu === action.payload.selectedMenu)
                        return;

                    if(state.meets.editId)
                        dispatch( meetEditToggled({ editId: null }) );

                    break;


                case SHOW_NOTIFICATION:
                    action.payload = { ...action.payload, isOpen: true };

                    break;


                case SHOW_CONFIRM_DIALOG:
                    action.payload = { ...action.payload, isOpen: true };

                    break;


                case SHOW_MODAL:
                    action.payload = { ...action.payload, isOpen: true };

                    break;

                case CLOSE_MODAL:
                    if(action.payload?.action)
                        dispatch(action.payload.action);

                    break;
            }

            return next(action);
        }
    }
}
