import {
    ORGS_LOADED,
    ORGS_PAGE_CHANGED,
    ORGS_PER_PAGE_CHANGED,
    ORGS_FORM_FIELD_CHANGED,
    ORGS_FORM_CLEAR,
    ORGS_FORM_EDIT,

    USERS_FORM_FIELD_CHANGED,
    USERS_FORM_CLEAR,
    USERS_FORM_EDIT,
    RESTRICTED_MEETS
} from './actionTypes';


const usersState = {
    orgs: [],
    page: 0,
    orgsPerPage: 10,
    multiTenant: false,

    orgsForm: {
        name: '',
        participants: 0,
        meetsPerTime: 1,
        licenses: [],
        exp: '01.01.2070',
        dedicatedJvb: false
    },

    form: {
        username: '',
        password: '',
        passwordConfirm: '',
        active: true,
        name: '',
        email: '',
        phone: '',
        isSecretary: true,
        allowArchive: false,
        restrictedMeets: false
    }
};


export function usersReducer(state = usersState, action) {
    switch (action.type) {
        case ORGS_LOADED:
            return {
                ...state,
                orgs: action.payload.orgs,
                multiTenant: action.payload.multiTenant
            }

        case ORGS_PAGE_CHANGED:
            return { ...state, page: action.payload.page }

        case ORGS_PER_PAGE_CHANGED:
            return { ...state, page: action.payload.page, orgsPerPage: action.payload.orgsPerPage }

        case ORGS_FORM_FIELD_CHANGED:
            return { ...state, orgsForm: { ...state.orgsForm, [action.payload.field]: action.payload.value } }

        case ORGS_FORM_CLEAR:
            return { ...state, orgsForm: usersState.orgsForm }

        case ORGS_FORM_EDIT:

            return {
                ...state,
                orgsForm: {
                    name: action.payload.org.name,
                    participants: action.payload.org.participants,
                    meetsPerTime: action.payload.org.meetsPerTime,
                    licenses: action.payload.org.licenses,
                    exp: action.payload.org.exp,
                    dedicatedJvb: action.payload.org.dedicatedJvb
                }
            }

        case USERS_FORM_FIELD_CHANGED:
            return { ...state, form: { ...state.form, [action.payload.field]: action.payload.value } }

        case RESTRICTED_MEETS:

            return { ...state, form: { ...state.form, [action.payload.field]: action.payload.value } }
        case USERS_FORM_CLEAR:
            return { ...state, form: usersState.form }

        case USERS_FORM_EDIT:
            return {
                ...state,
                form: {
                    ...state.form,
                    username: action.payload.user.username,
                    active: action.payload.user.active,
                    name: action.payload.user.name,
                    email: action.payload.user.email,
                    phone: action.payload.user.phone,
                    isSecretary: action.payload.user.isSecretary,
                    allowArchive: action.payload.user.allowArchive,
                    restrictedMeets: action.payload.user.restrictedMeets
                }
            }

        default:
            return state
    }
}
