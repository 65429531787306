import React from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withTranslation } from 'react-i18next';
import {
    restrictedMeets,
    usersFormFieldChanged,
    usresFormEdit
} from './actions';
import {
    TextField,
    FormControlLabel,
    Switch,
} from '@mui/material';

function Form({ user, t }) {
    const { _settings, _form, _modal } = useSelector(state => {
        return {
            _settings: state.app.settings,
            _form: state.users.form,
            _modal: state.main.modal
        }
    })
    const dispatch = useDispatch()
    useEffect(() => {
        if (_modal.title === 'orgs.editUser' && user)
            dispatch(usresFormEdit({ user }))
    }, [])
    const isEditing = _modal.title === 'orgs.editUser';
    return (
        <form onSubmit={() => { }}>
            <TextField
                variant="outlined"
                size="small"
                margin="dense"
                fullWidth
                required={!isEditing}
                disabled={isEditing}
                label={t('users.form.username')}
                name="username"
                value={_form.username}
                onChange={event => dispatch(usersFormFieldChanged({ field: event.target.name, value: event.target.value }))}
            />

            <TextField
                variant="outlined"
                size="small"
                margin="dense"
                fullWidth
                required={!isEditing}
                autoComplete="off"
                label={t('users.form.password')}
                name="password"
                value={_form.password}
                onChange={event => dispatch(usersFormFieldChanged({ field: event.target.name, value: event.target.value }))}
            />

            <TextField
                variant="outlined"
                size="small"
                margin="dense"
                fullWidth
                required={!isEditing}
                autoComplete="off"
                label={t('users.form.passwordConfirm')}
                name="passwordConfirm"
                value={_form.passwordConfirm}
                onChange={event => dispatch(usersFormFieldChanged({ field: event.target.name, value: event.target.value }))}
            />

            <TextField
                variant="outlined"
                size="small"
                margin="dense"
                fullWidth
                required
                label={t('users.form.name')}
                name="name"
                value={_form.name}
                onChange={event => dispatch(usersFormFieldChanged({ field: event.target.name, value: event.target.value }))}
            />

            <TextField
                variant="outlined"
                size="small"
                margin="dense"
                fullWidth
                label={t('users.form.email')}
                name="email"
                value={_form.email}
                onChange={event => dispatch(usersFormFieldChanged({ field: event.target.name, value: event.target.value }))}
            />

            <TextField
                variant="outlined"
                size="small"
                margin="dense"
                fullWidth
                label={t('users.form.phone')}
                name="phone"
                value={_form.phone}
                onChange={event => dispatch(usersFormFieldChanged({ field: event.target.name, value: event.target.value }))}
            />

            {user && user.isAdmin ?
                null
                :
                <FormControlLabel
                    label={t('users.form.isSecretary')}
                    labelPlacement="end"
                    control={
                        <Switch
                            name="isSecretary"
                            checked={_form.isSecretary}
                            onChange={event => dispatch(usersFormFieldChanged({ field: event.target.name, value: !_form.isSecretary }))}
                            color="primary"
                        />
                    }
                />
            }

            {_modal.title === 'orgs.addUser' || (user && user.isAdmin) ?
                null
                :
                <FormControlLabel
                    label={t('users.form.active')}
                    labelPlacement="end"
                    control={
                        <Switch
                            name="active"
                            checked={_form.active}
                            onChange={event => dispatch(usersFormFieldChanged({ field: event.target.name, value: !_form.active }))}
                            color="primary"
                        />
                    }
                />
            }

            {(user && user.isAdmin) || (!_settings.restrictedArchive) ?
                null
                :
                <>
                    <br />
                    <FormControlLabel
                        label={t('users.form.allowArchive')}
                        labelPlacement="end"
                        control={
                            <Switch
                                name="allowArchive"
                                checked={_form.allowArchive}
                                onChange={event => dispatch(usersFormFieldChanged({ field: event.target.name, value: !_form.allowArchive }))}
                                color="primary"
                            />
                        }
                    />
                </>
            }

            {(user && user.isAdmin) || (!_settings.restrictedMeets) ?
                null
                :
                <>
                    <br />
                    <FormControlLabel
                        label={t('users.form.restrictedMeets')}
                        labelPlacement="end"
                        control={
                            <Switch
                                name="restrictedMeets"
                                checked={_form.restrictedMeets}
                                onChange={event => dispatch(restrictedMeets({ field: event.target.name, value: !_form.restrictedMeets }))}
                                color="primary"
                            />
                        }
                    />
                </>
            }
        </form>
    )
}

export default withTranslation()(Form);
