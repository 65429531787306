import React from 'react';

import { connect, useSelector } from 'react-redux';

import {
  CssBaseline,
  Box
} from '@mui/material';

import NavBar from './header/NavBar';
import SideBar from './sidebar/SideBar';
import Content from './content/Content';
import Footer from '../footer/Footer';

import Notification from './Notification';
import ConfirmDialog from './ConfirmDialog';
import Modal from './Modal';


// class MainPage extends React.Component {
//   render() {
//     const { _isDrawerOpen } = this.props;

//     return (
//       <Box className="mainPage">

//         <CssBaseline />

//         <NavBar isDrawerOpen={_isDrawerOpen} />

//         <SideBar isDrawerOpen={_isDrawerOpen} />

//         <Box className={`contentWrapper ${_isDrawerOpen ? 'shift' : ''}`}>
//           <Box className="drawerHeader" />

//           <Box className="content">
//             <Content />
//           </Box>

//           <Footer />
//         </Box>

//         <Notification />

//         <ConfirmDialog />

//         <Modal />
//       </Box>
//     )
//   }
// }
function MainPage() {
  const { _isDrawerOpen } = useSelector(state => {
    return {
      _isDrawerOpen: state.main.isDrawerOpen
    }
  })

  return (
    <Box className="mainPage">

      <CssBaseline />

      <NavBar isDrawerOpen={_isDrawerOpen} />

      <SideBar isDrawerOpen={_isDrawerOpen} />

      <Box className={`contentWrapper ${_isDrawerOpen ? 'shift' : ''}`}>
        <Box className="drawerHeader" />

        <Box className="content">
          <Content />
        </Box>

        <Footer />
      </Box>

      <Notification />

      <ConfirmDialog />

      <Modal />
    </Box>
  )

}


function mapStateToProps(state) {
  return {
    _isDrawerOpen: state.main.isDrawerOpen
  }
}


export default connect(mapStateToProps)(MainPage);
