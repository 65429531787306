import React from 'react';

import AddUser from './AddUser';
import Edit from './Edit';
import Delete from './Delete';


function Actions({ org }) {
    return (
        <>
            <AddUser org={org} />
            <Edit org={org} />
            <Delete org={org} />
        </>
    )
}


export default Actions;
