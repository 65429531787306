import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import {
    meetEdit,
    meetEditToggled
} from './actions';
import {
    Container,
    Card,
    CardHeader,
    Divider,
    Box,
    Button
} from '@mui/material';
import Form from './form/Form';


class Edit extends React.Component {
    constructor( props ) {
        super( props );

        this.onEditSubmit = this.onEditSubmit.bind( this );
    }


    onEditSubmit( event ) {
        event.preventDefault();
        this.props.dispatch( meetEdit() );
    }


    render() {
        const {
            isRooms,
            isWebinars,
            updateList,
            _isCurrent,
            dispatch,
            t
        } = this.props;

        return(
            <Container maxWidth="md">
                <Card className="editMeet">
                    <CardHeader title={ `${ t( 'editMeet.title' ) }${ _isCurrent ? ' (' + t( 'editMeet.current' ) + ')' : '' }`} />

                    <Divider light />

                    <Form
                        isRooms={ isRooms }
                        isWebinars={ isWebinars}
                        optionsExpanded={ true }
                        submitBtnText={ t( 'editMeet.save' ) }
                        onFormSubmit={ this.onEditSubmit }
                    />

                    <Box className="back">
                        <Button
                            onClick={
                                () => {
                                    updateList();
                                    dispatch( meetEditToggled({ editId: null }) );
                                }
                            }
                        >
                            { t( 'editMeet.back' ) }
                        </Button>
                    </Box>
                </Card>
            </Container>
        )
    }
}


function mapStateToProps( state ) {
    const editId = state.meets.editId;
    return {
        _isCurrent: state.meets.current.includes( editId )
    }
}


export default connect( mapStateToProps )( withTranslation()( Edit ) );
