import React from 'react';
import { withTranslation } from 'react-i18next';
import {
    TableRow,
    TableCell
} from '@mui/material';
import { prettyDateTime } from '../../../PrettyDateTime';

function Items({ isPoolLicense, licenses, t }) {
    if (Array.isArray(licenses) && licenses.length > 0)
        return licenses.map(license => {
            return (
                <TableRow key={license._id}>
                    <TableCell align="center">{license.number}</TableCell>
                    <TableCell align="center">{license.id}</TableCell>
                    <TableCell align="right">{license.participants}</TableCell>
                    {
                        isPoolLicense
                            ?
                            null
                            :
                            <TableCell align="right">{license.rooms}</TableCell>
                    }
                    <TableCell align="right">
                        {prettyDateTime(license.startTime * 1000)}
                    </TableCell>
                    <TableCell align="right" className="endTime">
                        {prettyDateTime(license.endTime * 1000)}
                    </TableCell>
                    {
                        isPoolLicense
                            ?
                            null
                            :
                            <TableCell align="center">{license.used ? license.org.name : null}</TableCell>
                    }
                </TableRow>
            )
        })
    return (
        <TableRow>
            <TableCell colSpan={isPoolLicense ? 5 : 7} align="center"> {t('licenses.empty')}</TableCell>
        </TableRow>
    )

}
export default withTranslation()(Items);
