import {
    MEETS_FILTER_DATE_RANGE_CHANGED,
    MEETS_FILTER_ITEM_SELECTED,
    MEETS_FILTER_OPTION_TOGGLED,
    MEETS_FILTER_CLEAR
} from './actionTypes';


export function meetsFilterDateRangeChanged( dates ) {
    return {
        type: MEETS_FILTER_DATE_RANGE_CHANGED,
        dates
    }
}


export function meetsFilterItemSelected( field, value ) {
    return {
        type: MEETS_FILTER_ITEM_SELECTED,
        field,
        value
    }
}


export function meetsFilterOptionToggled( option ) {
    return {
        type: MEETS_FILTER_OPTION_TOGGLED,
        option
    }
}


export function meetsFilterClear() {
    return {
        type: MEETS_FILTER_CLEAR
    }
}
