import {
    MEET_GET_STATUS,
    MEET_START,
    MEET_SHOW_WEBINAR_REG,
    MEET_WEBINAR_REG_FORM_FIELD_CHANGED,
    MEET_WEBINAR_REG
} from './actionTypes';
import meetStatuses from './meetStatuses';


const meetState = {
    status: meetStatuses.LOADING,
    info: {},
    showInfo: false,
    limitStatus: null,
    limit: 0,
    webinarRegForm: {
        fio: '',
        phone: '',
        email: '',
        city: '',
        address: '',
        org: '',
        jobTitle: ''
    }
};


export function meetReducer(state = meetState, action) {
    switch( action.type ) {
        case MEET_GET_STATUS:
            return {
                ...state,
                status: action.status,
                info: action.info ? action.info : meetState.info,
                showInfo: action.showInfo ? action.showInfo : meetState.showInfo
            }

        case MEET_START:
            const info = state.info;
            if( action.info ) {
                for( const property of Object.keys(action.info) ) {
                    info[property] = action.info[property];
                }
            }
            return {
                ...state,
                status: action.status ? action.status : state.status,
                info,
                showInfo: action.showInfo !== undefined ? action.showInfo : state.showInfo,
                limitStatus: action.limitStatus ? action.limitStatus : meetState.limitStatus,
                limit: action.limitStatus ? action.limit : meetState.limit
            }

        case MEET_SHOW_WEBINAR_REG:
            return {
                ...state,
                status: meetStatuses.WEBINAR_REG
            }

        case MEET_WEBINAR_REG_FORM_FIELD_CHANGED:
            return {
                ...state,
                webinarRegForm: {
                    ...state.webinarRegForm,
                    [action.fieldName] : action.value
                }
            }

        case MEET_WEBINAR_REG:
            return {
                ...state,
                status: action.status
            }

        default:
            return state;
    }
}
