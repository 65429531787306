import {
    AUTH_CHECK,
    USER_UPDATED,
    AUTH_CHECKED,
    TS_UPDATED,
    LOG_IN,
    LOGGED_IN,
    LOG_IN_FAILED,
    LOG_OUT,
    LNG_CHANGED,
    BACKDROP_TOGGLED
} from './actionTypes';


export function authCheck() {
    return {
        type: AUTH_CHECK
    }
}


export function userUpdated(payload) {
    return {
        type: USER_UPDATED,
        payload
    }
}


export function authChecked(payload) {
    return {
        type: AUTH_CHECKED,
        payload
    }
}


export function tsUpdated() {
    return {
        type: TS_UPDATED
    }
}


export function logIn(payload) {
    return {
        type: LOG_IN,
        payload
    }
}


export function loggedIn(payload) {
    return {
        type: LOGGED_IN,
        payload
    }
}


export function logInFailed(payload) {
    return {
        type: LOG_IN_FAILED,
        payload
    }
}


export function logOut() {
    return {
        type: LOG_OUT
    }
}


export function lngChanged(payload) {
    return {
        type: LNG_CHANGED,
        payload
    }
}


export function backdropToggled( enabled = false ) {
    return {
        type: BACKDROP_TOGGLED,
        enabled
    }
}
