import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import {
    Box,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Typography
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DateRange from './DateRange';
import OrgsUsers from './OrgsUsers';
import Types from './Types';
import Options from './Options';
import { isPastMeets, isAllMeets } from '../functions';
import { showClearButton } from './functions';



function Filter({ isRooms, _showClearButton, t }) {
    return (
        <Box className="meetsFilter">
            <Accordion defaultExpanded={false}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="meetsFilter"
                    id="meetsFilter"
                >
                    <Typography>
                        {t('meetsFilter.title')}
                        {_showClearButton ? ` (${t('meetsFilter.active')})` : ''}
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <DateRange />
                    <OrgsUsers />
                    <Types isRooms={isRooms} />
                    <Options showClearButton={_showClearButton} />
                </AccordionDetails>
            </Accordion>
        </Box>
    )
}

function mapStateToProps(state, ownProps) {
    const { isRooms } = ownProps;
    const _isPastMeets = isPastMeets(state.meets.filterCategory);
    const _meetsFilter = state.meetsFilter;
    const _isAllMeets = isAllMeets(state.meets.filter);
    return {
        _showClearButton: showClearButton(_meetsFilter, isRooms, _isPastMeets, _isAllMeets)
    }
}


export default connect(mapStateToProps)(withTranslation()(Filter));
