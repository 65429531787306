import React from 'react';

import {
    VpnKeyTwoTone
} from '@mui/icons-material';

import pages from '../content/pages';

import AdminMenuItem from './AdminMenuItem';

function AdminLicensesItem() {
    return (
        <AdminMenuItem name={pages.ADMIN_LICENSES} icon={<VpnKeyTwoTone />} text="adminMenu.licenses" />
    )
}

export default AdminLicensesItem;
