export const formFields = {
    date: new Date(),
    durationHours: 1,
    durationMinutes: 0,
    peoples: 0,
    description: '${default}',
    lobby: false,
    autoLobby: false,
    meetAutoRecording: false,
    startAudioMuted: false,
    autoRecording: false,
    startVideoMuted: false,
    passEnabled: false,
    pass: '',
    periodic: false,
    daysOfWeek: {
        monday: false,
        tuestday: false,
        wednesday: false,
        thursday: false,
        friday: false,
        saturday: false,
        sunday: false
    },
    roomId: '',
    webinar: false,
    webinarReg: false,
    webinarFormFields: {
        fio: true,
        phone: false,
        email: false,
        city: false,
        address: false,
        org: false,
        jobTitle: false
    },
    autoOwner: false,
    license: null
}
