import React, { useState, useEffect, memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    licensesLoad
} from './actions';
import { withTranslation } from 'react-i18next';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Licenses from './Licenses';

const LicensesTab = memo(({ t }) => {
    const [expanded, setExpanded] = useState();
    const dispatch = useDispatch();
    const { _licenses, _filterLicenses, _participantsAvailable, _participantsLicensed } = useSelector(state => {
        return {
            _licenses: state.licenses.licenses,
            _ts: state.app.settings.ts,
            _filterLicenses: state.licenses.filterLicenses,
            _participantsAvailable: state.licenses.participantsAvailable,
            _participantsLicensed: state.licenses.participantsLicensed
        }
    });

    const [tabs, setTabs] = useState();
    const handleChange =
        (active) => (event, isExpanded) => {
            setExpanded(isExpanded ? active : false);
        };
    useEffect(() => {
        dispatch(licensesLoad());

    }, []);

    useEffect(() => {

        const licensesTabs = [
            {
                'title': t('licenses.licenses_tabs.endLicenses'), key: 'endLicenses', license: _filterLicenses.endLicenses,
            },
            { "title": t('licenses.licenses_tabs.currentLicenses'), key: 'currentLicenses', license: _filterLicenses.currentLicenses },
            { "title": t('licenses.licenses_tabs.futureLicenses'), 'key': 'futureLicenses', license: _filterLicenses.futureLicenses }
        ]

        setTabs(licensesTabs);
        setExpanded(licensesTabs[1].key + 1);
    }, [_licenses, _filterLicenses, _participantsAvailable, _participantsLicensed])

    return (
        <>
            {
                !!tabs ? tabs.map((item, index) => {
                    return item.license !== undefined && item.license.length !== 0 ? (
                        <Accordion expanded={expanded === item.key + index
                        } onChange={handleChange(item.key + index)} key={item.key}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1bh-content"
                                id="panel1bh-header"
                                sx={expanded === item.key + index ? { backgroundColor: "#f5f5f5" } : null}
                            >
                                <Typography component={'span'} sx={{ width: '33%', flexShrink: 0 }}>
                                    {item.title}
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography component={'span'}>
                                    <Licenses license={item.license} />
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                    ) : null
                }) : null
            }
        </>
    )
})

export default (withTranslation()(LicensesTab));