import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { archiveSetMeet } from './actions';
import filesize from 'filesize';
import { prettyDateTime } from '../../../../../PrettyDateTime';
import {
    Paper,
    Tabs,
    Tab,
    Box,
    TableContainer,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell
} from '@mui/material';
import {
    OndemandVideoTwoTone,
    InsertDriveFileTwoTone,
    EmojiPeopleTwoTone,
    CommentTwoTone
} from '@mui/icons-material';
import tabs from './tabs';
import Buttons from './Buttons';


function Archive({ t, meet }) {
    const [currentTab, setCurrentTab] = useState(tabs.RECORDINGS);
    const dispatch = useDispatch();
    const { _meet } = useSelector(state => {
        return {
            _meet: state.archive.meet
        }
    })

    useEffect(() => {
        dispatch(archiveSetMeet(meet));
    }, [meet])

    function renderTab() {
        if (_meet && _meet[currentTab] && _meet[currentTab].length > 0)
            return _meet[currentTab].map((file, index) => {
                const fileName = typeof (file.file) === 'string' ? file.file.split('/').pop() : undefined;

                return (
                    <TableRow key={index}>
                        <TableCell>{fileName}</TableCell>
                        <TableCell align="right">{filesize(file.size, { round: 0 })}</TableCell>
                        <TableCell align="right">{prettyDateTime(parseInt(file.time, 10))}</TableCell>
                        <TableCell align="right">
                            {
                                <Buttons
                                    fileType={currentTab}
                                    meetId={_meet._id}
                                    file={file.file}
                                    fileName={fileName}
                                    fileIndex={index}
                                />
                            }
                        </TableCell>
                    </TableRow>
                )
            });

        return (
            <TableRow>
                <TableCell colSpan={4} align="center">{t('archive.empty')}</TableCell>
            </TableRow>
        )
    }

    return (
        <>
            <Paper>
                <Tabs
                    textColor="primary"
                    indicatorColor="primary"
                    variant="fullWidth"
                    centered
                    value={currentTab}
                    onChange={(event, value) => { setCurrentTab(value) }}
                >
                    <Tab label={t(`archive.${tabs.RECORDINGS}`)} icon={<OndemandVideoTwoTone />} value={tabs.RECORDINGS} />
                    <Tab label={t(`archive.${tabs.FILES}`)} icon={<InsertDriveFileTwoTone />} value={tabs.FILES} />
                    <Tab label={t(`archive.${tabs.PARTICIPANTS}`)} icon={<EmojiPeopleTwoTone />} value={tabs.PARTICIPANTS} />
                    <Tab label={t(`archive.${tabs.CHAT}`)} icon={<CommentTwoTone />} value={tabs.CHAT} />
                </Tabs>
            </Paper>
            <Box className="archive">
                <TableContainer component={Paper}>
                    <Table aria-label="archive">
                        <TableHead>
                            <TableRow>
                                <TableCell>{t('archive.fileName')}</TableCell>
                                <TableCell align="right">{t('archive.fileSize')}</TableCell>
                                <TableCell align="right">{t('archive.fileTime')}</TableCell>
                                <TableCell align="right"></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>{renderTab()}</TableBody>
                    </Table>
                </TableContainer>
            </Box>
        </>
    )

}

export default withTranslation()(Archive);
