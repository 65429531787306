import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Button } from '@mui/material';
import { lngChanged } from '../app/actions';


function Header({ t }) {
    const { _isIzum, _lng } = useSelector(state => {
        return {
            _isIzum: state.app.isIzum,
            _lng: state.app.settings.lng
        }
    })
    const dispatch = useDispatch()
    if (_isIzum) {
        return (
            <div className="header izum">
                <a href="https://tatarstan.ru/" title={t('header.official')}>{t('header.official')}</a>
                <a href="https://president.tatarstan.ru/" title={t('header.president')}>{t('header.president')}</a>
                <a href="https://prav.tatarstan.ru/" title={t('header.prav')}>{t('header.prav')}</a>
                <a href="https://uslugi.tatarstan.ru/" title={t('header.uslugi')}>{t('header.uslugi')}</a>
                <a href="https://msu.tatarstan.ru/" title={t('header.msu')}>{t('header.msu')}</a>

                <Button
                    className={`lng ${_lng === 'ru' ? 'active' : ''}`}
                    onClick={() => { dispatch(lngChanged({ lng: 'ru' })) }}
                >
                    Рус
                </Button>
                <Button
                    className={`lng ${_lng === 'tat' ? 'active' : ''}`}
                    onClick={() => { dispatch(lngChanged({ lng: 'tat' })) }}
                >
                    Тат
                </Button>
                <Button
                    className={`lng ${_lng === 'en' ? 'active' : ''}`}
                    onClick={() => { dispatch(lngChanged({ lng: 'en' })) }}
                >
                    Eng
                </Button>
            </div>
        )
    }

    return (
        <div className="header"></div>
    )
}


export default (withTranslation()(Header));
