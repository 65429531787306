import React from 'react';
import { useSelector } from 'react-redux';
import { withTranslation } from 'react-i18next';
import {
    List,
    ListSubheader
} from '@mui/material';
import {
    MeetingRoomTwoTone,
    CoPresentTwoTone,
    TodayTwoTone,
    EventTwoTone,
    EventNoteTwoTone,
    DateRangeTwoTone,
    EventAvailableTwoTone
} from '@mui/icons-material';

import MeetsFilterItem from './MeetsFilterItem';

function MeetsFilterMenu({ t }) {
    const { _rooms, _webinars } = useSelector(state => {
        const { settings } = state.app;
        return {
            _rooms: settings.rooms,
            _webinars: settings.webinars
        }
    })
    return (
        <>
            {
                _rooms ?
                    <MeetsFilterItem
                        filterText={t('sideBar.meetsFilter.rooms')}
                        filterName={'rooms'}
                        filterIcon={<MeetingRoomTwoTone />}
                    />
                    :
                    null
            }
            {
                _webinars ?
                    <MeetsFilterItem
                        filterText={t('sideBar.meetsFilter.webinars')}
                        filterName={'webinars'}
                        filterIcon={<CoPresentTwoTone />}
                    />
                    :
                    null
            }
            <List
                subheader={
                    <ListSubheader component="div">
                        {t('sideBar.meetsFilter.future')}
                    </ListSubheader>
                }
            >
                <MeetsFilterItem
                    filterText={t('sideBar.meetsFilter.today')}
                    filterName={'today'}
                    filterIcon={<TodayTwoTone />}
                />
                <MeetsFilterItem
                    filterText={t('sideBar.meetsFilter.tomorrow')}
                    filterName={'tomorrow'}
                    filterIcon={<EventTwoTone />}
                />
                <MeetsFilterItem
                    filterText={t('sideBar.meetsFilter.week')}
                    filterName={'week'}
                    filterIcon={<DateRangeTwoTone />}
                />
                <MeetsFilterItem
                    filterText={t('sideBar.meetsFilter.all')}
                    filterName={'all'}
                    filterIcon={<EventNoteTwoTone />}
                />
                <MeetsFilterItem
                    filterText={t('sideBar.meetsFilter.current')}
                    filterName={'current'}
                    filterIcon={<EventAvailableTwoTone />}
                />
            </List>

            <List
                subheader={
                    <ListSubheader component="div">
                        {t('sideBar.meetsFilter.past')}
                    </ListSubheader>
                }
            >
                <MeetsFilterItem
                    filterText={t('sideBar.meetsFilter.today')}
                    filterName={'last_today'}
                    filterIcon={<EventTwoTone />}
                />
                <MeetsFilterItem
                    filterText={t('sideBar.meetsFilter.yesterday')}
                    filterName={'last_yesterday'}
                    filterIcon={<TodayTwoTone />}
                />
                <MeetsFilterItem
                    filterText={t('sideBar.meetsFilter.week')}
                    filterName={'last_week'}
                    filterIcon={<DateRangeTwoTone />}
                />
                <MeetsFilterItem
                    filterText={t('sideBar.meetsFilter.all')}
                    filterName={'last_all'}
                    filterIcon={<EventNoteTwoTone />}
                />
            </List>
        </>
    )
}
export default (withTranslation()(MeetsFilterMenu));
