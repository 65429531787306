import React from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { closeModal } from './actions';

import { withTranslation } from 'react-i18next';

import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button
} from '@mui/material';


function Modal({ t }) {
    const { _modal } = useSelector(state => {
        return {
            _modal: state.main.modal
        }
    })
    const dispatch = useDispatch();

    return (
        <Dialog
            fullWidth={true}
            maxWidth="md"
            open={_modal.isOpen}
            onClose={() => { }}
            aria-labelledby="modal-dialog-title"
            aria-describedby="modal-dialog-description"
        >
            <DialogTitle id="modal-dialog-title">
                {t(_modal.title)}
            </DialogTitle>
            <DialogContent>
                {_modal.form}
            </DialogContent>
            <DialogActions>
                {Boolean(_modal.actionButton) && Boolean(_modal.action) ?
                    <Button onClick={() => dispatch(_modal.action)} color="primary">
                        {t(_modal.actionButton)}
                    </Button>
                    :
                    null
                }
                <Button onClick={() => dispatch(closeModal(_modal.closeAction))} color="primary" autoFocus>
                    {Boolean(_modal.closeButton) ? t(_modal.closeButton) : t('modal.closeButton')}
                </Button>
            </DialogActions>
        </Dialog>
    )
}


export default withTranslation()(Modal);
