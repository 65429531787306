import React from 'react';

import { withTranslation } from 'react-i18next';

import { Typography } from '@mui/material';

import RecomendedBrowsers from './RecomendedBrowsers';


class UnsupportedBrowsers extends React.Component {
    render() {
        const { t } = this.props;

        return (
            <>
                <Typography variant="h6" paragraph={ true }>
                    { t('meet.browser.unSupported.message1') + '!' }
                </Typography>
        
                <Typography>
                    { t('meet.browser.unSupported.message2') + ': '}
                </Typography>
        
                <RecomendedBrowsers />
            </>
        )
    }
}


export default withTranslation()(UnsupportedBrowsers);
