import React from 'react';
import { connect } from 'react-redux';
import { authCheck } from './actions';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from 'react-router-dom';
import Loader from '../loader/Loader';
import LoginPage from '../login/LoginPage';
import MainPage from '../main/MainPage';
import Meet from '../meet/Meet';
import Room from '../room/Room';
import '../css/App.scss';


class App extends React.Component {
  componentDidMount() {
    this.props.dispatch( authCheck() );
  }

  render() {
    const { _user } = this.props;

    return (
      <>
        <Router>
          <Switch>
            <Route path={ '/meet/:meetId' }
              exact
              render={
                (match) => <Meet { ...match } />
              }
            />
            <Route path={ '/room' }
              exact
              render={
                () => <Room />
              }
            />
            <Route path={ '/room/:roomId' }
              exact
              render={
                (match) => <Room { ...match } />
              }
            />
            <Route path={ '/room/:roomId/:hash' }
              exact
              render={
                (match) => <Room { ...match } />
              }
            />
            <Route path={ '/webinar/:meetId' }
              exact
              render={
                (match) => <Meet { ...match } webinar={ true } />
              }
            />
            <Route path={ '/main' }
              exact
              render={
                () => !_user.isAuthChecked ?
                  <Redirect to={ '/' } />
                  :
                  <MainPage />
              }
            />
            <Route path={ '/login' }
              exact
              render={
                () => !_user.isAuthChecked ?
                  <Redirect to={ '/' } />
                  :
                  <LoginPage />
              }
            />
            <Route path="/"
              render={
                () => !_user.isAuthChecked ?
                  <Loader preloader={ true } />
                :
                  _user.name ?
                    <Redirect to={ '/main' } />
                  :
                    <Redirect to={ '/login' } />
              }
            />
          </Switch>
        </Router>

        <Loader />
      </>
    )
  }
}


function mapStateToProps(state) {
  return {
    _user: state.app.user
  }
}


export default connect(mapStateToProps)(App);
