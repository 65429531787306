import {
    AUTH_CHECK,
    AUTH_CHECKED,
    LOG_IN,
    LOGGED_IN,
    LNG_CHANGED,
    LOG_OUT
} from './actionTypes';
import {
    authCheck,
    authChecked,
    tsUpdated,
    loggedIn,
    logInFailed
} from './actions';
import i18n from './i18n';
import { appFetch } from './functions';
import { showNotification } from '../main/actions';


export function appMiddleware({ getState, dispatch }) {
    return function(next) {
        return function(action) {
            const state = getState();

            switch(action.type) {
                case AUTH_CHECK:
                    const fetchStart = Date.now();

                    appFetch(
                        state.app.backEnd + '/api/login/is_logged_in',
                        {
                            credentials: 'include'
                        },
                        (data) => {
                            if( data.settings ) {
                                const fetchDelay = Date.now() - fetchStart;

                                dispatch(
                                    authChecked({
                                        user: { ...data.user, isAuthChecked: true },
                                        orgsMap: { ...data.orgsMap },
                                        settings: {
                                            ...data.settings,
                                            ts: data.settings.ts + fetchDelay
                                        }
                                    })
                                );

                                setInterval(() => {
                                    dispatch( tsUpdated() )
                                }, state.app.tsUpdateInterval)

                                if( data.settings.title ) {
                                    document.getElementsByTagName('title')[0].innerText = data.settings.title;
                                }
                            }
                        },
                        dispatch,
                        () => {
                            setTimeout(() => dispatch( authCheck() ), 10000)
                        },
                        false
                    );

                    return;


                case AUTH_CHECKED:
                    // if(i18n.language !== action.payload.settings.lng)
                    //     i18n.changeLanguage(action.payload.settings.lng);

                    break;

                
                case LOG_IN:
                    appFetch(
                        state.app.backEnd + '/api/login',
                        {
                            credentials: 'include',
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/x-www-form-urlencoded'
                            },
                            body: 'username=' + action.payload.username + '&password=' + action.payload.password
                        },
                        (data) => {
                            if( data.status === 'ok' && data.user?.name && data.orgsMap )
                                dispatch( loggedIn({ user: data.user, orgsMap: data.orgsMap }) );
                            else if(data.status === 'auth_failed')
                                dispatch( logInFailed({ error: 'loginPage.badAuth' }) );
                            else if(data.status === 'auth_denied')
                                dispatch( logInFailed({ error: 'loginPage.authDenied' }) );
                            else
                                dispatch( logInFailed({ error: 'loginPage.error' }) );
                        },
                        dispatch,
                        () => {
                            dispatch( logInFailed({ error: 'loginPage.error' }) );
                        }
                    );

                    return;


                case LOGGED_IN:
                    action.payload.user = { ...action.payload.user, isAuthChecked: true };

                    break;


                case LOG_OUT:
                    appFetch(
                        state.app.backEnd + '/api/login/logout',
                        {
                            credentials: 'include'
                        },
                        (data) => {
                            console.log(data);
                        },
                        dispatch,
                        () => {
                            dispatch( showNotification({ severity: 'error', text: 'navBar.logoutError' }) )
                        }
                    )

                    return;


                case LNG_CHANGED:
                    if(state.app.settings.lng !== action.payload.lng)
                        i18n.changeLanguage(action.payload.lng);
                    else
                        return;
                    
                    break;
            }

            return next(action);
        }
    }
}
