import React from 'react';
import { connect } from 'react-redux';
import {
    meetDescriptionChanged,
    meetAdd
} from './actions';
import { withTranslation } from 'react-i18next';
import {
    Box,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Typography,
    Container
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Form from './form/Form';


class Add extends React.Component {
    constructor(props) {
        super(props);

        this.onAddSubmit = this.onAddSubmit.bind(this);
    }


    onAddSubmit(event) {
        event.preventDefault();
        this.props.dispatch( meetAdd() );
    }


    resetMeetDefaultDescription() {
        const { _form, dispatch, t } = this.props;

        if( _form.description === '${default}' ) {
            dispatch( meetDescriptionChanged({ description: t('meetForm.descriptionDefaultValue') }) );
        }
    }

    componentDidMount() {
        this.resetMeetDefaultDescription();
    }

    componentDidUpdate() {
        this.resetMeetDefaultDescription();
    }


    render() {
        const {isRooms, isWebinars, isCurrents, _user, _isLicenseLoaded, t } = this.props;

        if(!_user.isSecretary || isCurrents)
            return null;
        
        return(
            <Box className="addMeet">
                <Accordion defaultExpanded={ true }>
                    <AccordionSummary
                        expandIcon={ <ExpandMoreIcon /> }
                        aria-controls="addMeet"
                        id="addMeet"
                    >
                        <Typography>
                            {
                                isRooms ?
                                    t('addMeet.roomTitle')
                                :
                                    t('addMeet.title')
                            }
                        </Typography>
                    </AccordionSummary>

                    <AccordionDetails>
                        { !_isLicenseLoaded ?
                            <div className="emptyLicenses">{ t('licenses.empty') }</div>
                        :
                            <Container maxWidth="sm">
                                <Form
                                    isRooms={ isRooms }
                                    isWebinars={ isWebinars }
                                    optionsExpanded={ false }
                                    submitBtnText={ t('addMeet.create') }
                                    onFormSubmit={ this.onAddSubmit }
                                />
                            </Container>
                        }
                    </AccordionDetails>
                </Accordion>
            </Box>
        )
    }
}


function mapStateToProps(state) {
    return {
        _user: state.app.user,
        _isLicenseLoaded: state.app.settings.isLicenseLoaded,
        _form: state.meets.form
    }
}


export default connect(mapStateToProps)(withTranslation()(Add));
