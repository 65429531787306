import React from 'react';
import { connect } from 'react-redux';
import { Box } from '@mui/material';


class Logo extends React.Component {
    render() {
        const { _isIzum } = this.props;

        return(
            <Box className={ `meetLogo ${ _isIzum ? 'izum' : '' }` }></Box>
        )
    }
}


function mapStateToProps(state) {
    return {
        _isIzum: state.app.isIzum
    }
}


export default connect(mapStateToProps)(Logo);
