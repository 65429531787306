import {
    MEETS_FILTER_CHANGED,

    MEETS_LIST_UPDATE,
    MEETS_LIST_UPDATED,

    MEETS_PAGE_CHANGED,
    MEETS_PER_PAGE_CHANGED,

    MEET_EDIT_TOGGLED,

    MEET_DATE_CHANGED,
    MEET_DURATION_HOURS_CHANGED,
    MEET_DURATION_MINUTES_CHANGED,
    MEET_PEOPLES_CHANGED,
    MEETS_FORM_FIELD_CHANGED,
    MEET_DESCRIPTION_CHANGED,
    MEET_LOBBY_TOGGLED,
    MEET_AUTO_LOBBY_TOGGLED,
    MEET_AUTO_OWNER_TOGGLED,
    MEET_START_AUDIO_MUTED_TOGGLED,
    MEET_START_VIDEO_MUTED_TOGGLED,
    MEET_PASS_ENABLED_TOGGLED,
    MEET_PASS_CHANGED,
    MEET_PERIODIC_TOGGLED,
    MEET_DAYS_OF_WEEK_CHANGED,
    MEET_WEBINAR_REG_TOGGLED,
    MEET_WEBINAR_FORM_FIELD_TOGGLED,

    MEET_ADD,
    MEET_EDIT,
    MEET_DELETE,

    MEET_GET_MODERATOR_LINK,
    MEET_AUTO_RECORDING
} from './actionTypes';


export function meetsFilterChanged(payload) {
    return {
        type: MEETS_FILTER_CHANGED,
        payload
    }
}


export function meetsListUpdate(payload) {
    return {
        type: MEETS_LIST_UPDATE,
        payload
    }
}


export function meetsListUpdated(payload) {
    return {
        type: MEETS_LIST_UPDATED,
        payload
    }
}


export function meetsPageChanged(payload) {
    return {
        type: MEETS_PAGE_CHANGED,
        payload
    }
}


export function meetsPerPageChanged(payload) {
    return {
        type: MEETS_PER_PAGE_CHANGED,
        payload
    }
}


export function meetEditToggled(payload) {
    return {
        type: MEET_EDIT_TOGGLED,
        payload
    }
}


export function meetsFormFieldChanged(payload) {
    return {
        type: MEETS_FORM_FIELD_CHANGED,
        payload
    }
}


export function meetDateChanged(payload) {
    return {
        type: MEET_DATE_CHANGED,
        payload
    }
}


export function meetDurationHoursChanged(payload) {
    return {
        type: MEET_DURATION_HOURS_CHANGED,
        payload
    }
}


export function meetDurationMinutesChanged(payload) {
    return {
        type: MEET_DURATION_MINUTES_CHANGED,
        payload
    }
}


export function meetPeoplesChanged(payload) {
    return {
        type: MEET_PEOPLES_CHANGED,
        payload
    }
}


export function meetDescriptionChanged(payload) {
    return {
        type: MEET_DESCRIPTION_CHANGED,
        payload
    }
}


export function meetLobbyToggled() {
    return {
        type: MEET_LOBBY_TOGGLED
    }
}


export function meetAutoLobbyToggled() {
    return {
        type: MEET_AUTO_LOBBY_TOGGLED
    }
}


export function meetAutoOwnerToggled() {
    return {
        type: MEET_AUTO_OWNER_TOGGLED
    }
}


export function meetStartAudioMutedToggled() {
    return {
        type: MEET_START_AUDIO_MUTED_TOGGLED
    }
}
export function meetAutoRecording() {
    return {
        type: MEET_AUTO_RECORDING
    }
}


export function meetStartVideoMutedToggled() {
    return {
        type: MEET_START_VIDEO_MUTED_TOGGLED
    }
}


export function meetPassEnabledToggled() {
    return {
        type: MEET_PASS_ENABLED_TOGGLED
    }
}


export function meetPassChanged(payload) {
    return {
        type: MEET_PASS_CHANGED,
        payload
    }
}


export function meetPeriodicToggled() {
    return {
        type: MEET_PERIODIC_TOGGLED
    }
}


export function meetDaysOfWeekChanged(payload) {
    return {
        type: MEET_DAYS_OF_WEEK_CHANGED,
        payload
    }
}


export function meetWebinarRegToggled(webinarReg) {
    return {
        type: MEET_WEBINAR_REG_TOGGLED,
        webinarReg
    }
}


export function meetWebinarFormFieldToggled(fieldName) {
    return {
        type: MEET_WEBINAR_FORM_FIELD_TOGGLED,
        fieldName
    }
}


export function meetAdd() {
    return {
        type: MEET_ADD
    }
}


export function meetEdit() {
    return {
        type: MEET_EDIT
    }
}


export function meetDelete(payload) {
    return {
        type: MEET_DELETE,
        payload
    }
}


export function meetGetModeratorLink(meetId, meetURL, roomId, play = false) {
    return {
        type: MEET_GET_MODERATOR_LINK,
        meetId,
        meetURL,
        roomId,
        play
    }
}
