import {
    ORGS_LOAD,
    ORGS_LOADED,
    ORGS_PAGE_CHANGED,
    ORGS_PER_PAGE_CHANGED,
    ORGS_FORM_FIELD_CHANGED,
    ORGS_FORM_CLEAR,
    ORGS_FORM_EDIT,
    ORG_ADD,
    ORG_DEL,
    ORG_EDIT,

    USERS_FORM_FIELD_CHANGED,
    USERS_FORM_CLEAR,
    USERS_FORM_EDIT,
    USER_ADD,
    USER_DEL,
    USER_EDIT,
    RESTRICTED_MEETS
} from './actionTypes';


export function orgsLoad() {
    return {
        type: ORGS_LOAD
    }
}


export function orgsLoaded(payload) {
    return {
        type: ORGS_LOADED,
        payload
    }
}


export function orgsPageChanged(payload) {
    return {
        type: ORGS_PAGE_CHANGED,
        payload
    }
}


export function orgsPerPageChanged(payload) {
    return {
        type: ORGS_PER_PAGE_CHANGED,
        payload
    }
}


export function orgsFormFieldChanged(payload) {
    return {
        type: ORGS_FORM_FIELD_CHANGED,
        payload
    }
}


export function orgsFormClear() {
    return {
        type: ORGS_FORM_CLEAR
    }
}


export function orgsFormEdit(payload) {
    return {
        type: ORGS_FORM_EDIT,
        payload
    }
}


export function orgAdd() {
    return {
        type: ORG_ADD,
    }
}


export function orgDel(payload) {
    return {
        type: ORG_DEL,
        payload
    }
}


export function orgEdit(payload) {
    return {
        type: ORG_EDIT,
        payload
    }
}


export function usersFormFieldChanged(payload) {
    return {
        type: USERS_FORM_FIELD_CHANGED,
        payload
    }
}
export function restrictedMeets(payload) {
    return {
        type: RESTRICTED_MEETS,
        payload
    }
}


export function usersFormClear() {
    return {
        type: USERS_FORM_CLEAR
    }
}


export function usresFormEdit(payload) {
    return {
        type: USERS_FORM_EDIT,
        payload
    }
}


export function userAdd(payload) {
    return {
        type: USER_ADD,
        payload
    }
}


export function userDel(payload) {
    return {
        type: USER_DEL,
        payload
    }
}


export function userEdit(payload) {
    return {
        type: USER_EDIT,
        payload
    }
}
