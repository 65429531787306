import React from 'react';

import { connect } from 'react-redux';

import { showConfirmDialog } from '../../../../actions';

import { orgDel } from '../../actions';

import { Delete as DeleteIcon } from '@mui/icons-material';

import Action from '../../../../Action';


class Delete extends React.Component {
    render() {
        const { org, dispatch } = this.props;

        if( org.name === 'default' )
            return null;

        return(
            <Action
                title={ 'orgs.del.title' }
                icon={ <DeleteIcon /> }
                onClickHandle={
                    () => {
                        dispatch(
                            showConfirmDialog({
                                title: 'orgs.del.title',
                                text: {
                                    key: 'orgs.del.text',
                                    vars: { org: org.name }
                                },
                                actionButton: 'orgs.del.button',
                                action: orgDel({ id: org._id })
                            })
                        )
                    }
                }
            >
            </Action>
        )
    }
}


export default connect()(Delete);
