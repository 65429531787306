import React from 'react';
import { useSelector } from 'react-redux';
import { Backdrop, CircularProgress } from '@mui/material';
function Loader({ preloader }) {
    const { _isBackdropOpen } = useSelector(state => {
        return {
            _isBackdropOpen: state.app.isBackdropOpen
        }
    })
    return (
        <Backdrop open={preloader || _isBackdropOpen} className="loader">
            <CircularProgress color="inherit" />
        </Backdrop>
    )

}

export default Loader
