import React from 'react';
import { useSelector } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Typography } from '@mui/material';


function Logo({ t }) {
    const { _settings, _isIzum } = useSelector(state => {
        return {
            _settings: state.app.settings,
            _isIzum: state.app.isIzum
        }
    })
    let logo = <a href="/" title={_settings.title}></a>;
    let title = 'logo.title';
    if (_isIzum) {
        logo = (
            <>
                <a href="https://cit.tatarstan.ru" title={`${t('logo.citrt.str1')} ${t('logo.citrt.str2')}`}></a>
                <a href="https://cit.tatarstan.ru" className="logo-description">
                    {t('logo.citrt.str1')}
                    <br />
                    {t('logo.citrt.str2')}
                </a>
            </>
        );
        title = 'logo.citrt.title';
    }

    return (
        <div className={`logo ${_isIzum ? 'izum' : ''}`}>
            {logo}
            <Typography variant="h5" className="title">
                {t(title)}
            </Typography>
        </div>
    )

}

export default (withTranslation()(Logo));
