import {
    MEET_GET_STATUS,
    MEET_START,
    MEET_SHOW_WEBINAR_REG,
    MEET_WEBINAR_REG_FORM_FIELD_CHANGED,
    MEET_WEBINAR_REG
} from './actionTypes';

import meetStatuses from './meetStatuses';


export function meetGetStatus( meetId, webinar ) {
    return {
        type: MEET_GET_STATUS,
        meetId,
        webinar
    }
}


export function meetStart( meetId, startInApp = false ) {
    return {
        type: MEET_START,
        meetId,
        startInApp
    }
}


export function meetShowWebinarReg() {
    return {
        type: MEET_SHOW_WEBINAR_REG
    }
}


export function meetWebinarRegFormFieldChanged(fieldName, value) {
    return {
        type: MEET_WEBINAR_REG_FORM_FIELD_CHANGED,
        fieldName,
        value
    }
}


export function meetWebinarReg(webinarReg, alreadyRegistered = false) {
    const status = !webinarReg || alreadyRegistered ? meetStatuses.STARTED : meetStatuses.WEBINAR_REG_SUCCEEDED;
    return {
        type: MEET_WEBINAR_REG,
        webinarReg,
        alreadyRegistered,
        status
    }
}