import React from 'react';
import { useSelector } from 'react-redux';
import {
    List
} from '@mui/material';

import AdminUsersItem from './AdminUsersItem';
import AdminLicensesItem from './AdminLicensesItem'

function AdminMenu() {
    const { _user } = useSelector(state => {
        return {
            _user: state.app.user
        }
    });

    if (!_user.isAdmin)
        return null;

    return (
        <List>
            <AdminLicensesItem />
            <AdminUsersItem />
        </List>
    )

}
export default (AdminMenu);
