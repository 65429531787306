import React from 'react';
import { meetGetModeratorLink } from '../../actions';
import Action from '../../../../Action';
import { Stars } from '@mui/icons-material';
import { useDispatch } from 'react-redux';

function CopyModeratorLink({ meet, meetURL }) {
    const dispatch = useDispatch()
    return (
        <Action
            title={'meetsList.copyToClipboardModeratorLink'}
            icon={<Stars />}
            onClickHandle={
                () => {
                    dispatch(meetGetModeratorLink(meet._id, meetURL, meet.roomId))
                }
            }
        >
        </Action>
    )
}

export default CopyModeratorLink;
