import React from 'react';

import { connect } from 'react-redux';

import {
    Box,
    CssBaseline,
    Container,
    Card
} from '@mui/material';

import Logo from './Logo';


class MeetPage extends React.Component {
    componentDidMount() {
        const externalApi = document.createElement('script');
        externalApi.src = this.props._externalApi;
        document.body.appendChild(externalApi);
    }


    render() {
        return(
            <Box className="meetPage">
                <CssBaseline />

                <Container maxWidth="lg">
                    <Logo />

                    <Card>
                        <Box className="info">
                            { this.props.children }
                        </Box>
                    </Card>
                </Container>
            </Box>
        )
    }
}


function mapStateToProps(state) {
    return {
        _externalApi: state.app.externalApi
    }
}


export default connect(mapStateToProps)(MeetPage);
