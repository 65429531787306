import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { meetGetStatus } from './actions';
import { prettyDateTime } from '../PrettyDateTime';
import { prettyDuration } from '../PrettyDuration';
import { Typography } from '@mui/material';
import meetStatuses from './meetStatuses';
import Message from './Message';
import Links from './Links';
import Limits from './Limits';
import WebinarRegForm from './WebinarRegForm';


class Info extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            waitSettingsInterval: null,
            loadInterval: null
        };

        this.loadMeet = this.loadMeet.bind(this);
    }


    loadMeet() {
        const { meetId, webinar, _settings, dispatch } = this.props;
        if(
            _settings?.appUrlScheme
            && _settings?.androidApp
            && _settings?.iosApp
            && _settings?.loadMeetInfoInterval
        ) {
            clearInterval( this.state.waitSettingsInterval );
            const { loadMeetInfoInterval } = _settings;
            dispatch( meetGetStatus( meetId, webinar ) );
            const loadInterval = setInterval(() => { dispatch( meetGetStatus( meetId, webinar ) ) }, loadMeetInfoInterval);
            this.setState({ loadInterval });
        }
    }


    componentDidMount() {
        const waitSettingsInterval =  setInterval(this.loadMeet, 1000);
        this.setState({ waitSettingsInterval });
    }


    componentWillUnmount() {
        clearInterval( this.state.loadInterval );
    }

    renderMessage() {
        const { webinar, meetId, _meet, t } = this.props;
        const { status } = _meet;
        const { webinarReg } = _meet.info;
        const isPersonalWebinarLink = webinar && meetId[0] === 'p';

        if (status === meetStatuses.FINISHED) {
            return <Typography>{t(`meet.${status}`)}</Typography>
        }

        if (webinar && (webinarReg || status === meetStatuses.WEBINAR_REG) && !isPersonalWebinarLink) {
            return <WebinarRegForm />
        }

        return (
            <Message>
                <Links meetId={ meetId } webinar={ webinar } isPersonalWebinarLink={ isPersonalWebinarLink } />
                <Limits />
            </Message>
        )
    }


    render() {
        const { _meet, t } = this.props;
        const { showInfo, status } = _meet;
        const { duration, description, date } = _meet.info;

        if (showInfo) {
            const { hours, minutes } = prettyDuration(duration);

            return(
                <>
                    <Typography>
                        {` ${t('meet.description')}: `}
                        <i>{description}</i>
                    </Typography>
                    <br />
                    <Typography>
                        {` ${t('meet.datetime')}: `}
                        <b>{prettyDateTime(date) }</b>
                    </Typography>
                    <Typography>
                        {` ${t('meet.duration')}: `}
                        <b>
                            {` ${hours} ${t('meetForm.duration.hours')} `}
                            {` ${minutes} ${t('meetForm.duration.minutes')} `}
                        </b>
                    </Typography>
                    <br />

                    {this.renderMessage()}
                </>
            )
        }

        return <Typography>{t(`meet.${status}`)}</Typography>
    }
}

function mapStateToProps(state) {
    return {
        _meet: state.meet,
        _settings: state.app.settings
    }
}

export default connect(mapStateToProps)(withTranslation()(Info));
