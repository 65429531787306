import React from 'react';

import { connect, useDispatch, useSelector } from 'react-redux';

import {
    showConfirmDialog,
    showModal,
    showNotification
} from '../../actions';

import {
    userDel,
    userEdit,
    usersFormClear
} from './actions';

import {
    Chip
} from '@mui/material';

import {
    StarTwoTone,
    PersonTwoTone,
    SupervisorAccountTwoTone,
    ArchiveTwoTone
} from '@mui/icons-material';

import Form from './Form';

function Users({ users }) {
    const { _settings } = useSelector(state => {
        return {
            _settings: state.app.settings
        }
    })
    const dispatch = useDispatch();
    if (Array.isArray(users) && users.length > 0)
        return (
            <>
                {
                    users.map(user => {
                        let icon = <PersonTwoTone />;

                        if (user.isAdmin)
                            icon = <StarTwoTone />;
                        else if (_settings.restrictedArchive && user.allowArchive)
                            icon = <ArchiveTwoTone />;
                        else if (user.isSecretary)
                            icon = <SupervisorAccountTwoTone />;

                        return (
                            <div key={user._id}>
                                <Chip
                                    variant="outlined"
                                    icon={icon}
                                    label={user.username + ': ' + user.name}
                                    onClick={
                                        () => {
                                            dispatch(
                                                showModal({
                                                    title: 'orgs.editUser',
                                                    form: <Form user={user} />,
                                                    actionButton: 'users.edit.button',
                                                    action: userEdit({ id: user._id }),
                                                    closeAction: { action: usersFormClear() }
                                                })
                                            )
                                        }
                                    }

                                    onDelete={
                                        () => {
                                            if (!user.isAdmin)
                                                dispatch(
                                                    showConfirmDialog({
                                                        title: 'users.del.title',
                                                        text: {
                                                            key: 'users.del.text',
                                                            vars: {
                                                                user: { login: user.username, name: user.name }
                                                            }
                                                        },
                                                        actionButton: 'users.del.delete',
                                                        action: userDel({ id: user._id })
                                                    })
                                                )
                                            else
                                                dispatch(showNotification({ severity: 'error', text: 'users.del.admin' }))
                                        }
                                    }
                                />
                            </div>
                        )
                    })
                }
            </>
        )

    return null;

}

export default (Users);
