const isPastMeets = filterCategory => {
    return filterCategory === 'past';
}

const isAllMeets = filter => {
    return ['all', 'last_all'].includes(filter);
}

const isCurrentMeets = filter => {
    return ['current'].includes(filter);
}


const mapDaysOfWeek = number => {
    switch (number) {
        case 1:
            return 'monday';
        case 2:
            return 'tuestday';
        case 3:
            return 'wednesday';
        case 4:
            return 'thursday';
        case 5:
            return 'friday';
        case 6:
            return 'saturday';
        default:
            return 'sunday';
    }
}

export { mapDaysOfWeek, isCurrentMeets, isAllMeets, isPastMeets }
