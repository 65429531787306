import {
    AUTH_CHECKED,
    USER_UPDATED,
    TS_UPDATED,
    LOGGED_IN,
    LOG_IN_FAILED,
    LNG_CHANGED,
    BACKDROP_TOGGLED
} from './actionTypes';

const { REACT_APP_DEV, REACT_APP_HOST_PORT } = process.env;
const isDev = Boolean(REACT_APP_DEV);
const locationHost =
    isDev ?
        Boolean(REACT_APP_HOST_PORT) ?
            REACT_APP_HOST_PORT
        :
            'dev.mintconf.ru'
    :
        window.location.host;
const urlBase =
    isDev ?
        window.location.origin
    :
        `https://${locationHost}`;
const backEnd =
    isDev ?
        Boolean(REACT_APP_HOST_PORT) ?
            `https://${locationHost}`
        :
            'http://127.0.0.1:9000'
    :
        urlBase;
const externalApi = `https://${locationHost}/external_api.js`;

document.domain = isDev ? locationHost.replace(/:\d+/, '') : document.domain;

const appState = {
    isIzum: process.env.REACT_APP_BUILD === 'izum',
    locationHost,
    urlBase,
    backEnd,
    externalApi,
    tsUpdateInterval: 10000,
    settings: {
        ts: Date.now()
    },
    user: {
        isAuthChecked: false,
    },
    orgsMap: {},
    isBackdropOpen: false
}

export function appReducer(state = appState, action) {
    switch(action.type) {
        case AUTH_CHECKED:
            return {
                ...state,
                settings: action.payload.settings,
                user: action.payload.user,
                orgsMap: action.payload.orgsMap
            };

        case USER_UPDATED:
            const user = { ...state.user };
            for( const key of Object.keys(action.payload.user) )
                user[key] = action.payload.user[key];
            return { ...state, user };

        case TS_UPDATED:
            return { ...state, settings: { ...state.settings, ts: state.settings.ts + state.tsUpdateInterval } };

        case LOGGED_IN:
            return { ...state, user: action.payload.user, orgsMap: action.payload.orgsMap };
        
        case LOG_IN_FAILED:
            return { ...state, user: { ...state.user, error: action.payload.error} };
        
        case LNG_CHANGED:
            return { ...state, settings: { ...state.settings, lng: action.payload.lng } };

        case BACKDROP_TOGGLED:
            return { ...state, isBackdropOpen: action.enabled };
        
        default:
            return state;
    }
}
