import React from 'react';

import { withTranslation } from 'react-i18next';

import { isBrowserWarning } from './functions';

import { Typography } from '@mui/material';

import RecomendedBrowsers from './RecomendedBrowsers';


class WarningBrowsers extends React.Component {
    render() {
        const { t } = this.props;

        if( isBrowserWarning() )
            return(
                <>
                    <br />

                    <Typography paragraph={ true }>
                        <b>{ t('meet.browser.warning.message1') + ' ' }</b>
                        { t('meet.browser.warning.message2') + '.' }
                    </Typography>

                    <Typography>
                        { t('meet.browser.warning.message3') + ':' }

                        <RecomendedBrowsers />
                    </Typography>
                </>
            );

        return null;
    }
}


export default withTranslation()(WarningBrowsers);
