import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { logIn } from '../app/actions';
import { Redirect } from 'react-router-dom';
import {
    Box,
    TextField,
    FormControl,
    InputLabel,
    OutlinedInput,
    InputAdornment,
    IconButton,
    FormHelperText,
    Button
} from '@mui/material';
import { VisibilityOff, Visibility } from '@mui/icons-material';

class LoginForm extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            username: '',
            password: '',
            hidden: true
        };
    }

    onInputChange(event) {
        this.setState({ [event.target.name]: event.target.value });
    }

    toggleShow() {
        this.setState({ hidden: !this.state.hidden });
    }

    onLogin(event) {
        event.preventDefault();
        const { username, password } = this.state;
        this.props.dispatch(logIn({ username, password }));
    }

    render() {
        const { _user, t } = this.props;
        const { username, password, hidden } = this.state;

        if (_user.name) {
            return (
                <Redirect to="/" />
            )
        }

        return (
            <form onSubmit={event => this.onLogin(event)}>
                <Box className="loginForm">
                    <TextField
                        variant="outlined"
                        size="small"
                        margin="dense"
                        fullWidth
                        label={t('loginPage.login')}
                        name="username"
                        value={username}
                        onChange={event => this.onInputChange(event)}
                        autoComplete="username"
                        error={Boolean(_user.error)}
                    />

                    <FormControl
                        variant="outlined"
                        size="small"
                        margin="dense"
                        fullWidth
                        error={Boolean(_user.error)}
                    >
                        <InputLabel
                            htmlFor="hidden-password"
                        >{t('loginPage.password')}</InputLabel>
                        <OutlinedInput
                            id="hidden-password"
                            type={hidden ? 'password' : 'text'}
                            label={t('loginPage.password')}
                            name="password"
                            value={password}
                            onChange={event => this.onInputChange(event)}
                            autoComplete="current-password"
                            endAdornment={
                                /**
                                 * By the security reason, Chrome limits access to the autofilled field value from JS level
                                 * and the value becomes available only after first user interaction with the page.
                                 * So, always show toggle button.
                                 */
                                <InputAdornment position="end">
                                    <IconButton
                                        onClick={() => this.toggleShow()}
                                        aria-label="toggle password visibility"
                                        edge="end"
                                    >
                                        {hidden ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                    </FormControl>
                    <FormHelperText error={Boolean(_user.error)}>{t(_user.error)}</FormHelperText>
                    <Box className="buttons">
                        <Button variant="contained" type="submit">{t('loginPage.loginBtn')}</Button>
                    </Box>
                </Box>
            </form>
        )
    }
}

function mapStateToProps(state) {
    return {
        _user: state.app.user
    }
}

export default connect(mapStateToProps)(withTranslation()(LoginForm));
