import React from 'react';
import { connect } from 'react-redux';
import { meetsListUpdate, meetEditToggled } from './actions';
import Add from './Add';
import List from './list/List';
import Edit from './Edit';


class Meets extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            updateListInterval: null,
        };

        this.updateList = this.updateList.bind( this );
    }


    updateList( background = false ) {
        const { _filter, dispatch } = this.props;
        dispatch( meetsListUpdate({ filter: _filter, background }) );
    }


    initMeetFrom() {
        const { dispatch } = this.props;
        dispatch( meetEditToggled({ editId: null }) );
    }


    componentDidMount() {
        const { _updateMeetsListInterval } = this.props;
        this.initMeetFrom();
        this.updateList();
        const updateListInterval = setInterval( () => this.updateList( true ), _updateMeetsListInterval );
        this.setState({ updateListInterval });
    }


    componentWillUnmount() {
        clearInterval( this.state.updateListInterval );
    }


    render() {
        const { _editId, _isRooms, _isWebinars, _isCurrents } = this.props;

        if(_editId)
            return <Edit isRooms={ _isRooms } isWebinars={ _isWebinars } updateList={ this.updateList } />

        return(
            <>
                <Add isRooms={ _isRooms } isWebinars={ _isWebinars } isCurrents={ _isCurrents } />
                <List isRooms={ _isRooms } />
            </>
        )
    }
}


function mapStateToProps(state) {
    const { filter } = state.meets;
    return {
        _editId: state.meets.editId,
        _filter: filter,
        _isRooms: filter === 'rooms',
        _isWebinars: filter === 'webinars',
        _isCurrents: filter === 'current',
        _updateMeetsListInterval: state.app.settings.updateMeetsListInterval
    }
}


export default connect( mapStateToProps )( Meets );
