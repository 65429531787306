import React from 'react';
import { useSelector } from 'react-redux';
import pages from './pages';
import Meets from './meets/Meets';
import Orgs from './users/orgs/Orgs';
import Profile from './profile/Profile';
import LicensesTab from './licenses/LicensesTab';

function Content() {
    const { _selectedMenu } = useSelector(state => {
        return {
            _selectedMenu: state.main.selectedMenu
        }
    })
    switch (_selectedMenu) {
        case pages.ADMIN_LICENSES:
            return <LicensesTab />

        case pages.ADMIN_USERS:
            return <Orgs />

        case pages.PROFILE:
            return <Profile />

        default:
            return <Meets />
    }
}
export default (Content);
