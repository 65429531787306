import React, { forwardRef } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { withTranslation } from 'react-i18next';
import {
    meetDateChanged,
    meetDurationHoursChanged,
    meetDurationMinutesChanged,
    meetPeoplesChanged,
    meetsFormFieldChanged,
    meetDescriptionChanged
} from '../actions';
import {
    FormControl,
    InputLabel,
    Select,
    TextField,
    Box,
    Button
} from '@mui/material';
import { AppRegistration } from '@mui/icons-material';
import { showModal, closeModal } from '../../../actions';
import WebinarRegistration from './WebinarRegistration';
import InputMask from 'react-input-mask';
import DatePicker, { registerLocale } from 'react-datepicker';
import Options from './Options';
import 'react-datepicker/dist/react-datepicker.css';
import ru from 'date-fns/locale/ru';
import { ORGS_FORM_CLEAR } from '../../users/actionTypes';


registerLocale('ru', ru);



function Form({ t, isRooms, optionsExpanded, submitBtnText, isWebinars, onFormSubmit }) {
    const dispatch = useDispatch();
    const { _form, _editId, _settings, _peoplesLicensed, _peoplesAvailable, _isPoolLicense, _licensesAvailable, _isCurrent, _limitPerConf, _currentConference } = useSelector(state => {
        const editId = state.meets.editId;
        const currentIndex = state.meets.current.indexOf(editId);
        const isCurrent = currentIndex > -1;
        return {
            _form: state.meets.form,
            _editId: editId,
            _settings: state.app.settings,
            _peoplesLicensed: state.licenses.peoplesLicensed,
            _peoplesAvailable: state.licenses.peoplesAvailable,
            _isPoolLicense: state.app.settings.isPoolLicense,
            _licensesAvailable: state.licenses.licensesAvailable,
            _isCurrent: isCurrent,
            _limitPerConf: state.app.settings.limitPerConf,
            _currentConference: isCurrent ? state.meets.currentConferences[currentIndex] : null
        }
    })
    function renderPoolFields() {


        return (
            <TextField
                variant="outlined"
                size="small"
                margin="dense"
                fullWidth
                required
                label={t('meetsList.peoples')}
                value={_form.peoples}
                helperText={
                    t('meetsList.peoplesHelperText', { peoplesAvailable: _peoplesAvailable, peoplesLicensed: _peoplesLicensed })
                    + (
                        _isCurrent ?
                            `, ${t('meetsList.peoplesCurrentHelperText', { peoplesCurrent: _currentConference.participants })}`
                            :
                            ''
                    )
                }
                error={
                    _form.peoples > _peoplesAvailable
                    || (_isCurrent && _form.peoples < _currentConference.participants)
                }
                onChange={event => dispatch(meetPeoplesChanged({ peoples: event.target.value }))}
            />
        )
    }


    function renderRoomFields() {

        const isLicensesAvailable = _licensesAvailable.length > 0;
        return (
            <FormControl variant="outlined" size="small" margin="dense" fullWidth required>
                <InputLabel htmlFor="meetLicenses">
                    {isLicensesAvailable ?
                        t('meetForm.licenses')
                        :
                        t('meetForm.noLicenses')
                    }
                </InputLabel>
                <Select
                    native
                    label={isLicensesAvailable ?
                        t('meetForm.licenses')
                        :
                        t('meetForm.noLicenses')
                    }
                    inputProps={{
                        id: 'meetLicenses'
                    }}
                    name="license"
                    value={_form.license ? _form.license : ''}
                    onChange={
                        event => {
                            dispatch(meetsFormFieldChanged({ field: event.target.name, value: event.target.value }))
                        }
                    }
                    disabled={!isLicensesAvailable || _isCurrent}
                >
                    {!isLicensesAvailable ?
                        <option key="0" value=""></option>
                        :
                        _licensesAvailable.map(license => (
                            <option
                                key={license._id}
                                value={license._id}
                            >
                                {`ID: ${license.number} (${license.participants} ${t('licenses.participantsCount')})`}
                            </option>
                        ))
                    }
                </Select>
            </FormControl>
        )
    }





    const DatePickerInput = forwardRef(({ value, onClick }, ref) => (
        <TextField
            disabled={_isCurrent}
            variant="outlined"
            size="small"
            margin="dense"
            fullWidth
            required
            label={_form.periodic ? t('meetsList.time') : t('meetsList.datetime')}
            onClick={onClick}
            value={value}
        />
    ))

    /*function timeFilter(time) {
        console.log(time);
        const currentDate = new Date();
        const selectedDate = new Date(time);
        return currentDate.getTime() < selectedDate.getTime();
    }*/

    return (
        <form onSubmit={event => onFormSubmit(event)} className="meetForm">
            {
                isRooms ?
                    <InputMask
                        disabled={_isCurrent}
                        mask="99-99-99"
                        value={_form.roomId}
                        onChange={
                            event => {
                                dispatch(meetsFormFieldChanged({ field: event.target.name, value: event.target.value }))
                            }
                        }
                    >
                        {
                            () =>
                                <TextField
                                    disabled={_isCurrent}
                                    variant="outlined"
                                    size="small"
                                    margin="dense"
                                    fullWidth
                                    required
                                    helperText={t('meetForm.roomIdHelperText')}
                                    label={t('meetForm.roomId')}
                                    name="roomId"
                                />
                        }
                    </InputMask>
                    :
                    <>
                        <DatePicker
                            disabled={_isCurrent}
                            selected={_form.date}
                            onChange={date => dispatch(meetDateChanged({ date }))}
                            dateFormat={_form.periodic ? 'HH:mm' : 'Pp'}
                            minDate={new Date(_settings.ts)}
                            showTimeSelect
                            showTimeSelectOnly={_form.periodic}
                            timeFormat='p'
                            timeIntervals={5}
                            locale='ru'
                            customInput={<DatePickerInput ref={React.createRef()} />}
                        />

                        <br />
                        <span className="duration">{t('meetsList.duration')}</span>
                        <br />

                        <FormControl variant="outlined" size="small" margin="dense" required>
                            <InputLabel id="meetDurationHours">{t('meetForm.duration.hours')}</InputLabel>
                            <Select
                                disabled={_isCurrent}
                                labelId="meetDurationHours"
                                label={t('meetForm.duration.hours')}
                                native
                                value={_form.durationHours}
                                onChange={event => dispatch(meetDurationHoursChanged({ durationHours: event.target.value }))}
                            >
                                {
                                    Array.from(
                                        new Array(24), (v, k) => (
                                            <option key={k} value={k}>{k}</option>
                                        )
                                    )
                                }
                            </Select>
                        </FormControl>

                        <FormControl variant="outlined" size="small" margin="dense" required>
                            <InputLabel id="meetDurationMinutes">{t('meetForm.duration.minutes')}</InputLabel>
                            <Select
                                disabled={_isCurrent}
                                labelId="meetDurationMinutes"
                                label={t('meetForm.duration.minutes')}
                                native
                                value={_form.durationMinutes}
                                onChange={event => dispatch(meetDurationMinutesChanged({ durationMinutes: event.target.value }))}
                            >
                                {
                                    _form.durationHours > 0 ?
                                        <option key={0} value={0}>{0}</option>
                                        :
                                        null
                                }
                                <option key={15} value={15}>{15}</option>
                                <option key={30} value={30}>{30}</option>
                                <option key={45} value={45}>{45}</option>
                            </Select>
                        </FormControl>
                    </>
            }

            {
                _isPoolLicense ?
                    _limitPerConf ?
                        renderPoolFields()
                        :
                        null
                    :
                    renderRoomFields()
            }

            <TextField
                variant="outlined"
                size="small"
                margin="dense"
                fullWidth
                required
                label={
                    isRooms ?
                        t('meetsList.roomDescription')
                        :
                        t('meetsList.description')
                }
                value={_form.description}
                onChange={event => dispatch(meetDescriptionChanged({ description: event.target.value }))}
            />

            <Options
                optionsExpanded={optionsExpanded}
                isRooms={isRooms}
                isWebinars={isWebinars}
                isCurrent={_isCurrent}
            />

            <Box className="buttons">
                {_form.webinarReg && !_isCurrent ?
                    <div>
                        <Button
                            variant="outlined"
                            endIcon={<AppRegistration />}
                            onClick={
                                () => {
                                    dispatch(
                                        showModal({
                                            title: 'meetForm.webinar.reg.title',
                                            form: <WebinarRegistration />,
                                            closeButton: 'meetForm.webinar.reg.close'
                                        })
                                    )
                                }
                            }
                        >
                            {t('meetForm.webinar.reg.title')}
                        </Button>
                    </div>
                    :
                    null
                }
                <Button variant="contained" type="submit">{submitBtnText}</Button>
            </Box>
        </form>
    )

}



export default withTranslation()(Form);
