import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { store } from './store/store';
import { createTheme, ThemeProvider } from '@mui/material';
import Loader from './loader/Loader';
import App from './app/App';

const theme = createTheme({
  typography: {
    fontFamily: [
      store.getState().app.isIzum ? 'Roboto' : 'Manrope',
      'Arial',
      'sans-serif'
    ].join(',')
  }
});

ReactDOM.render(
  <Provider store={ store }>
    <React.Suspense fallback={ <Loader preloader={ true } /> }>
      <ThemeProvider theme={ theme }>
        <App />
      </ThemeProvider>
    </React.Suspense>
  </Provider>,
  document.getElementById('root')
);
