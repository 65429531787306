import { ARCHIVE_SET_MEET } from './actionTypes';


const archiveState = {
    meet: {}
};


export function archiveReducer(state = archiveState, action) {
    switch( action.type ) {
        case ARCHIVE_SET_MEET:
            return { ...state, meet: action.payload.meet };

        default:
            return state;
    }
}
