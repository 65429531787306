import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { meetStart, meetShowWebinarReg, meetWebinarReg } from './actions';
import { isMobile } from './functions';
import { isIOS } from 'react-device-detect';
import { Typography } from '@mui/material';
import meetStatuses from './meetStatuses';


class Links extends React.Component {
    renderLinks() {
        const { meetId, webinar, isPersonalWebinarLink, _meet, _settings, dispatch, t } = this.props;

        if( isMobile() && !webinar ) {
            return(
                <>
                    { `${ t('meet.mobile.start1') } ` }

                    <a
                        href="#"
                        onClick={
                            event=> {
                                event.preventDefault();
                                dispatch( meetStart( meetId, true ) );
                            }
                        }
                    >
                        { t('meet.mobile.start2') }
                    </a>

                    <br />

                    { `${ t('meet.mobile.loadFrom') } ` }

                    {
                        isIOS
                        ?
                            <a href={ _settings.iosApp } target="_blank">
                                App Store
                            </a>
                        :
                            <a href={ _settings.androidApp } target="_blank">
                                Google Play
                            </a>
                    }

                    <br />

                    { `${ t('meet.mobile.message1') }? ` }

                    <a
                        href="#"
                        onClick={
                            event=> {
                                event.preventDefault();
                                dispatch( meetStart( meetId ) );
                            }
                        }
                    >
                        { t('meet.mobile.message2') }
                    </a>
                </>
            )
        }

        return(
            <>
                { `${ t('meet.showLink.message1') } ` }
                <a
                    href="#"
                    onClick={
                        event => {
                            event.preventDefault();
                            if (webinar) {
                                if (isPersonalWebinarLink) {
                                    dispatch(meetWebinarReg(true, true));
                                } else {
                                    dispatch(meetShowWebinarReg());
                                }
                            } else {
                                dispatch( meetStart( meetId ) ); // Because it's PC
                            }
                        }
                    }
                >
                    { t('meet.showLink.message2') }
                </a>
            </>
        )
    }


    render() {
        const { _meet, _settings, t } = this.props;
        const { status, info } = _meet;

        switch( status ) {
            case meetStatuses.SHOWLINK:
                return (
                    <Typography>
                        { info.jwt ? `${ t('meet.moderatorMessage') }. ` : null }
                        { this.renderLinks() }
                    </Typography>
                )

            case meetStatuses.NOTSTARTED:
                return(
                    <Typography>
                        <b>{ `${ t('meet.notStarted.message1') }...` }</b>
                        <br />
                        { `${ t('meet.notStarted.message2') }.` }
                        <br />
                        <br />
                        { `${ t('meet.notStarted.message3') }.` }
                    </Typography>
                )

            default:
                return(
                    <Typography>
                        { `${ t('meet.tooEarly.message1') } ` }
                        { `${ _settings.showLinkTimeout } ` }
                        { `${ t('meet.tooEarly.message2') }.` }
                    </Typography>
                )
        }
    }
}


function mapStateToProps(state) {
    return {
        _meet: state.meet,
        _settings: state.app.settings
    }
}


export default connect(mapStateToProps)(withTranslation()(Links));
