import {
    MEET_GET_STATUS,
    MEET_START,
    MEET_WEBINAR_REG
} from './actionTypes';
import meetStatuses from './meetStatuses';
import { isMobile } from './functions';
import { showNotification } from '../main/actions';


export const meetMiddleware = ({ getState, dispatch }) => next => action => {
    const state = getState();
    const { backEnd, settings } = state.app;

    switch (action.type) {
        case MEET_GET_STATUS:
            if (state.meet.status === meetStatuses.WEBINAR_REG_SUCCEEDED) {
                return;
            }

            const meetType = action.webinar ? 'webinar' : 'meet';

            fetch(
                `${backEnd}/api/meet/status/${meetType}/${action.meetId}`,
                {
                    credentials: 'include'
                }
            )
                .then(response => { return response.json() })
                .then(
                    data => {
                        if (!data.status)
                            throw new Error(`Incorrect response to get meet status request: ${JSON.stringify(data)}`);

                        if (
                            [
                                meetStatuses.ERROR,
                                meetStatuses.NOTFOUND
                            ].includes(data.status)
                        ) {
                            action.status = data.status;
                        } else if (
                            [
                                meetStatuses.TOOEARLY,
                                meetStatuses.SHOWLINK,
                                meetStatuses.NOTSTARTED,
                                meetStatuses.FINISHED
                            ].includes(data.status)
                            && data.meet
                        ) {
                            action.status = data.status;
                            action.info = data.meet;
                            action.showInfo = true;

                            /**
                             * Don't resets WEBINAR_REG status if it's setted.
                             */
                            if (
                                data.status === meetStatuses.SHOWLINK &&
                                state.meet.status === meetStatuses.WEBINAR_REG
                            ) {
                                action.status = meetStatuses.WEBINAR_REG;
                            }
                        } else {
                            throw new Error(
                                `Incorrect status of get meet status request or empty meet info: ${JSON.stringify(data)}`
                            );
                        }

                        next(action);
                    }
                )
                .catch(error => {
                    console.error(error.message);
                    action.status = meetStatuses.ERROR;
                    next(action);
                });

            return;


        case MEET_START:
            let meetId = action.meetId;
            let hash = '';
            if (meetId.indexOf('_') !== -1) {
                [hash, meetId] = meetId.split('_');
            }
            fetch(
                `${backEnd}/api/meet/start/meet/${meetId}`
                + (hash ? `/${hash}` : ``),
                {
                    credentials: 'include'
                }
            )
                .then(response => { return response.json() })
                .then(
                    data => {
                        if (!data.status) {
                            throw new Error(`Incorrect response to start meet request: ${JSON.stringify(data)}`);
                        }

                        if (data.status === meetStatuses.STARTED && Object.keys(data.meet).length > 0) {
                            if (isMobile() && action.startInApp) {
                                const meetURL =
                                    `${settings.appUrlScheme}://${window.location.host}/${state.meet.info._id}`
                                    + (state.meet.info.jwt ? `?jwt=${state.meet.info.jwt}` : ``);

                                window.location = meetURL;
                                return;
                            }

                            action.status = meetStatuses.STARTED;
                            action.info = data.meet;
                        } else if (
                            [
                                meetStatuses.ERROR,
                                meetStatuses.NOTFOUND,
                            ].includes(data.status)
                        ) {
                            action.status = data.status;
                            action.showInfo = false;
                        } else if (
                            [
                                meetStatuses.PARTICIPANTSLIMIT,
                                meetStatuses.ORGLIMIT,
                                meetStatuses.LICENSEUSED
                            ].includes(data.status)
                            && typeof (data.limit) === 'number'
                        ) {
                            action.limitStatus = data.status;
                            action.limit = data.limit;
                        } else {
                            throw new Error(
                                `Incorrect status of start meet request or empty limit value: ${JSON.stringify(data)}`
                            );
                        }

                        next(action);
                    }
                )
                .catch(
                    error => {
                        console.error(error.message);
                        action.status = meetStatuses.ERROR;
                        action.showInfo = false;
                        next(action);
                    }
                );

            return;

        case MEET_WEBINAR_REG:
            if (action.alreadyRegistered) {
                const autoPlayHack = document.getElementById('autoPlayHack');
                autoPlayHack.play();
                next(action);
                return;
            }
            fetch(
                `${backEnd}/api/meet/reg/${state.meet.info._id}`,
                {
                    credentials: 'include',
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ ...state.meet.webinarRegForm })
                },
            )
                .then(response => { return response.json() })
                .then(
                    data => {
                        if (data.status === meetStatuses.STARTED) {
                            if (!action.webinarReg) {
                                const autoPlayHack = document.getElementById('autoPlayHack');
                                autoPlayHack.play();
                            }
                            next(action);
                            return;
                        }

                        if (data.error) {
                            dispatch(
                                showNotification({ severity: 'error', text: `meetForm.webinar.reg.error.${data.error}` })
                            );
                            return;
                        }

                        throw new Error(`Incorrect response to reg meet request: ${JSON.stringify(data)}`);
                    }
                )
                .catch(error => {
                    console.error(error.message);
                    dispatch(
                        showNotification({ severity: 'error', text: `meetForm.webinar.reg.error.error` })
                    );
                });

            return;
    }

    return next(action);
}
