import React from 'react';

import { withTranslation } from 'react-i18next';

import {
    Box,
    CssBaseline,
    Container,
    Card,
    CardHeader,
    Divider
} from '@mui/material';

import Header from '../header/Header';
import Logo from '../header/Logo';
import LoginForm from './LoginForm';
import Footer from '../footer/Footer';


function LoginPage({ t }) {
    return (
        <Box className="loginPage">
            <CssBaseline />
            <Header />
            <Logo />
            <Container maxWidth="sm" className="container">
                <Card className="card">
                    <CardHeader title={t('loginPage.title')} className="title" />
                    <Divider light />
                    <LoginForm />
                </Card>
            </Container>
            <Footer />
        </Box>
    )

}
export default withTranslation()(LoginPage);
