import {
    DRAWER_TOGGLED,
    MENU_CHANGED,
    SHOW_NOTIFICATION,
    CLOSE_NOTIFICATION,
    SHOW_CONFIRM_DIALOG,
    CLOSE_CONFIRM_DIALOG,
    SHOW_MODAL,
    CLOSE_MODAL
} from './actionTypes';


const mainState = {
    isDrawerOpen: true,

    selectedMenu: 'MeetsFilter', // MeetsFilter, AdminUsers

    notification: {
        isOpen: false,
        severity: 'error',
        text: '',
        textAttr: {}
    },

    confirmDialog: {
        isOpen: false,
        title: '',
        text: '',
        actionButton: '',
        action: {}
    },

    modal: {
        isOpen: false,
        title: '',
        form: null,
        actionButton: '',
        action: {},
        closeButton: '',
        closeAction: {}
    }
}


export function mainReducer(state = mainState, action) {
    switch(action.type) {
        case DRAWER_TOGGLED:
            return { ...state, isDrawerOpen: !state.isDrawerOpen }

        case MENU_CHANGED:
            return { ...state, selectedMenu: action.payload.selectedMenu }

        case SHOW_NOTIFICATION:
            return { ...state, notification: action.payload }

        case CLOSE_NOTIFICATION:
            return { ...state, notification: { ...state.notification, isOpen: false } }

        case SHOW_CONFIRM_DIALOG:
            return { ...state, confirmDialog: action.payload }

        case CLOSE_CONFIRM_DIALOG:
            return { ...state, confirmDialog: { ...state.confirmDialog, isOpen: false } }

        case SHOW_MODAL:
            return { ...state, modal: action.payload }

        case CLOSE_MODAL:
            return { ...state, modal: mainState.modal }
        
        default:
            return state
    }
}
