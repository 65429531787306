import {
    LICENSES_LOAD,
    LICENSES_LOADED,
    LICENSES_LOAD_BY_PROFILE,
    LICENSES_AVAILABLE_LOAD,
    LICENSES_AVAILABLE_LOADED,
    FILTER_LICENSES,
    NEW_FILTER_LICENSES,
} from './actionTypes';


export function licensesLoad() {
    return {
        type: LICENSES_LOAD
    }
}


export function licensesLoaded(payload) {
    return {
        type: LICENSES_LOADED,
        payload
    }
}


export function licensesLoadByProfile() {
    return {
        type: LICENSES_LOAD_BY_PROFILE
    }
}


export function licensesAvailableLoad(payload) {
    return {
        type: LICENSES_AVAILABLE_LOAD,
        payload
    }
}


export function licensesAvailableLoaded(payload) {
    return {
        type: LICENSES_AVAILABLE_LOADED,
        payload
    }
}

export function filterLicenses(payload) {
    return {
        type: FILTER_LICENSES,
        payload
    }
}

export function newFilterLicenses(payload) {
    return {
        type: NEW_FILTER_LICENSES,
        payload
    }
}
