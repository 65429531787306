import { ROOM_JOIN, ROOM_START_MOBILE_IFRAME } from "./actionTypes";

export function roomJoin(roomId, hash) {
    return {
        type: ROOM_JOIN,
        roomId,
        hash
    }
}

export function roomStartMobileIframe() {
    return {
        type: ROOM_START_MOBILE_IFRAME
    }
}
