export function prettyDateTime(timestamp) {
    const dateTime = new Date(timestamp);
    return(
        ('0' + dateTime.getDate()).slice(-2) +
        '.' +
        ('0' + (dateTime.getMonth() + 1)).slice(-2) +
        '.' +
        dateTime.getFullYear() +
        ' ' +
        ('0' + dateTime.getHours()).slice(-2) +
        ':' +
        ('0' + dateTime.getMinutes()).slice(-2)
    )
}
