import {
    LICENSES_LOAD,
    LICENSES_LOAD_BY_PROFILE,
    LICENSES_AVAILABLE_LOAD,
    FILTER_LICENSES,

} from './actionTypes';

import {
    licensesLoaded,
    licensesAvailableLoaded,
    filterLicenses,
    newFilterLicenses
} from './actions'

import {
    userUpdated
} from '../../../app/actions';

import {
    meetsFormFieldChanged
} from '../meets/actions'

import {
    showNotification
} from '../../actions';

import {
    appFetch
} from '../../../app/functions';

export const licensesMiddleware = ({ getState, dispatch }) => next => action => {
    const state = getState();
    switch (action.type) {
        case LICENSES_LOAD:
            appFetch(
                state.app.backEnd + '/api/licenses',
                {
                    credentials: 'include'
                },
                (data) => {
                    if (data.status === 'ok' && data.licenses) {
                        if (data.participantsLicensed) {
                            dispatch(
                                licensesLoaded({
                                    licenses: data.licenses,
                                    participantsLicensed: data.participantsLicensed,
                                    participantsAvailable: data.participantsAvailable
                                })
                            )
                            dispatch(filterLicenses({
                                licenses: data.licenses
                            }))
                        } else {
                            dispatch(
                                licensesLoaded({
                                    licenses: data.licenses
                                })
                            )
                            dispatch(filterLicenses({
                                licenses: data.licenses
                            }))
                        }
                    } else
                        dispatch(showNotification({ severity: 'error', text: 'licenses.loadError' }));
                },
                dispatch,
                () => {
                    dispatch(showNotification({ severity: 'error', text: 'licenses.loadError' }));
                }
            )

            return;

        case LICENSES_LOAD_BY_PROFILE:
            appFetch(
                state.app.backEnd + '/api/licenses/profile',
                {
                    credentials: 'include'
                },
                (data) => {
                    if (data.status === 'ok' && data.org) {
                        dispatch(userUpdated({ user: { org: data.org } }));
                    } else
                        dispatch(showNotification({ severity: 'error', text: 'licenses.loadError' }));
                },
                dispatch,
                () => {
                    dispatch(showNotification({ severity: 'error', text: 'licenses.loadError' }));
                }
            )
            return;
        case LICENSES_AVAILABLE_LOAD:
            if (state.app.settings.isPoolLicense && !state.app.settings.limitPerConf) {
                return
            } else {
                appFetch(
                    state.app.backEnd + '/api/licenses/available',
                    {
                        credentials: 'include',
                        method: 'POST',
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify({
                            date: Date.parse(action.payload.date),
                            duration: action.payload.duration,
                            editId: state.meets.editId,
                            // TODO: if no selected days, then periodic must be false
                            periodic: state.meets.form.periodic,
                            daysOfWeek: state.meets.form.daysOfWeek,
                            room: Boolean(action.payload.room)
                        })
                    },
                    (data) => {
                        if (data.status === 'ok') {



                            dispatch(
                                licensesAvailableLoaded({
                                    peoplesLicensed: data.peoplesLicensed,
                                    peoplesAvailable: data.peoplesAvailable,
                                    licensesAvailable: data.licensesAvailable
                                })
                            );

                            if (
                                !state.app.settings.isPoolLicense
                                && data.licensesAvailable.length > 0
                                && (!state.meets.form.license || action.payload.forceSelect)
                            ) {
                                dispatch(meetsFormFieldChanged({ field: 'license', value: data.licensesAvailable[0]._id }));
                            }


                        }
                    },
                    dispatch
                )
            }


            return;
        case FILTER_LICENSES:
            if (action.payload.licenses.length !== 0) {
                const filteredLicenses = {
                    endLicenses: [],
                    currentLicenses: [],
                    futureLicenses: []
                }
                action.payload.licenses.forEach(license => {
                    if (license.endTime * 1000 <= state.app.settings.ts) {
                        filteredLicenses.endLicenses.push(license)
                    }
                    if (license.startTime * 1000 < state.app.settings.ts && license.endTime * 1000 > state.app.settings.ts) {
                        filteredLicenses.currentLicenses.push(license)
                    }
                    if (license.endTime * 1000 > state.app.settings.ts && license.startTime * 1000 > state.app.settings.ts) {
                        filteredLicenses.futureLicenses.push(license)
                    }

                })
                dispatch(newFilterLicenses(filteredLicenses))
                return filteredLicenses;
            }
            return

        default:
            break
    }
    next(action);
}
