export const ORGS_LOAD = 'ORGS_LOAD';

export const ORGS_LOADED = 'ORGS_LOADED';

export const ORGS_PAGE_CHANGED = 'ORGS_PAGE_CHANGED';

export const ORGS_PER_PAGE_CHANGED = 'ORGS_PER_PAGE_CHANGED';

export const ORGS_FORM_FIELD_CHANGED = 'ORGS_FORM_FIELD_CHANGED';

export const ORGS_FORM_CLEAR = 'ORGS_FORM_CLEAR';

export const ORGS_FORM_EDIT = 'ORGS_FORM_EDIT';

export const ORG_ADD = 'ORG_ADD';

export const ORG_DEL = 'ORG_DEL';

export const ORG_EDIT = 'ORG_EDIT';


export const USERS_FORM_FIELD_CHANGED = 'USERS_FORM_FIELD_CHANGED';

export const USERS_FORM_CLEAR = 'USERS_FORM_CLEAR';

export const USERS_FORM_EDIT = 'USERS_FORM_EDIT';

export const USER_ADD = 'USER_ADD';

export const USER_DEL = 'USER_DEL';

export const USER_EDIT = 'USER_EDIT';

export const RESTRICTED_MEETS = 'RESTRICTED_MEETS';

