import React from 'react';
import { useDispatch } from 'react-redux';
import {
    orgEdit,
    orgsFormClear
} from '../../actions';
import { showModal } from '../../../../actions';
import { Edit as EditIcon } from '@mui/icons-material';
import Action from '../../../../Action';
import Form from '../Form';



function Edit({ org }) {

    const dispatch = useDispatch()
    return (
        <Action
            title={'orgs.edit.title'}
            icon={<EditIcon />}
            onClickHandle={
                () => {
                    dispatch(
                        showModal({
                            title: 'orgs.edit.title',
                            form: <Form org={org} />,
                            actionButton: 'orgs.edit.button',
                            action: orgEdit({ id: org._id, licenses: org.licenses }),
                            closeAction: { action: orgsFormClear() }
                        })
                    )
                }
            }
        />
    )

}
export default Edit;
