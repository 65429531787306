import {
	ORGS_LOAD,
	ORGS_FORM_FIELD_CHANGED,
	ORGS_FORM_EDIT,
	ORG_ADD,
	ORG_DEL,
	ORG_EDIT,

	USERS_FORM_FIELD_CHANGED,
	USER_ADD,
	USER_DEL,
	USER_EDIT
} from './actionTypes';

import {
	orgsLoad,
	orgsLoaded,
	orgsFormClear,
	usersFormClear,
	orgsFormFieldChanged
} from './actions';

import {
	licensesLoad
} from '../licenses/actions';

import {
	showNotification,
	closeConfirmDialog,
	closeModal
} from '../../actions';

import {
	appFetch
} from '../../../app/functions';

import {
	prettyDateTime
} from '../../../PrettyDateTime';


export function usersMiddleware({ getState, dispatch }) {
	return function (next) {
		return function (action) {
			const state = getState();

			switch (action.type) {
				case ORGS_LOAD:
					appFetch(
						state.app.backEnd + '/api/orgs',
						{
							credentials: 'include'
						},
						(data) => {
							if (data.status === 'ok' && data.orgs) {
								dispatch(
									orgsLoaded({
										orgs: data.orgs,
										multiTenant: data.multiTenant
									})
								);

								dispatch(licensesLoad());
							} else
								dispatch(showNotification({ severity: 'error', text: 'orgs.loadError' }));
						},
						dispatch,
						() => {
							dispatch(showNotification({ severity: 'error', text: 'orgs.loadError' }));
						}
					);

					return;


				case ORGS_FORM_FIELD_CHANGED:
					switch (action.payload.field) {
						case 'name':
							action.payload.value = action.payload.value.replace(/\s{2,}/, ' ');
							break;

						case 'participants':
							action.payload.value = action.payload.value.replace(/[^\d]/, '');
							break;

						case 'meetsPerTime':
							action.payload.value = parseInt(action.payload.value.replace(/[^\d]/, ''), 10) || '';
							break;
					}

					break;


				case ORGS_FORM_EDIT:
					action.payload.org.exp = prettyDateTime(action.payload.org.exp).split(' ')[0];

					break;


				case ORG_ADD:
					appFetch(
						state.app.backEnd + '/api/orgs',
						{
							credentials: 'include',
							method: 'POST',
							headers: { 'Content-Type': 'application/json' },
							body: JSON.stringify({
								name: state.users.orgsForm.name,
								participants: state.users.orgsForm.participants,
								meetsPerTime: state.users.orgsForm.meetsPerTime,
								licenses: state.users.orgsForm.licenses,
								exp: state.users.orgsForm.exp,
								dedicatedJvb: state.users.orgsForm.dedicatedJvb
							})
						},
						(data) => {
							if (data.status === 'ok') {
								dispatch(showNotification({ severity: 'success', text: 'orgs.add.succeeded' }));
								dispatch(closeModal({ action: orgsFormClear() }));
								dispatch(orgsLoad());

							} else if (data.status === 'error')
								dispatch(showNotification({ severity: 'error', text: 'orgs.add.error' }));

							else {
								if (data.status === 'participants_incorrect')
									dispatch(orgsLoad());
								else if (data.status === 'license_already_used') {
									dispatch(orgsLoad());
									dispatch(orgsFormFieldChanged({ field: 'licenses', value: [] }));
								}

								dispatch(showNotification({ severity: 'error', text: 'orgs.add.' + data.status }));
							}
						},
						dispatch,
						() => {
							dispatch(showNotification({ severity: 'error', text: 'orgs.add.error' }));
						}
					);

					return;


				case ORG_DEL:
					appFetch(
						state.app.backEnd + '/api/orgs',
						{
							credentials: 'include',
							method: 'DELETE',
							headers: { 'Content-Type': 'application/json' },
							body: JSON.stringify({
								id: action.payload.id
							})
						},
						(data) => {
							if (data.status === 'ok') {
								dispatch(showNotification({ severity: 'success', text: 'orgs.del.succeeded' }));
								dispatch(closeConfirmDialog());
								dispatch(orgsLoad());

							} else
								dispatch(showNotification({ severity: 'error', text: 'orgs.del.error' }));
						},
						dispatch,
						() => {
							dispatch(showNotification({ severity: 'error', text: 'orgs.del.error' }));
						}
					);

					return;


				case ORG_EDIT:
					appFetch(
						state.app.backEnd + '/api/orgs',
						{
							credentials: 'include',
							method: 'PUT',
							headers: { 'Content-Type': 'application/json' },
							body: JSON.stringify({
								id: action.payload.id,
								name: state.users.orgsForm.name,
								participants: state.users.orgsForm.participants,
								meetsPerTime: state.users.orgsForm.meetsPerTime,
								licenses: state.users.orgsForm.licenses,
								exp: state.users.orgsForm.exp,
								dedicatedJvb: state.users.orgsForm.dedicatedJvb
							})
						},
						(data) => {
							if (data.status === 'ok') {
								dispatch(showNotification({ severity: 'success', text: 'orgs.edit.succeeded' }));
								dispatch(closeModal({ action: orgsFormClear() }));
								dispatch(orgsLoad());

							} else if (data.status === 'error')
								dispatch(showNotification({ severity: 'error', text: 'orgs.edit.error' }));

							else {
								if (data.status === 'participants_incorrect')
									dispatch(orgsLoad());
								else if (data.status === 'license_already_used') {
									dispatch(orgsLoad());
									dispatch(orgsFormFieldChanged({ field: 'licenses', value: action.payload.licenses }));
								}

								dispatch(showNotification({ severity: 'error', text: 'orgs.edit.' + data.status }));
							}
						},
						dispatch,
						() => {
							dispatch(showNotification({ severity: 'error', text: 'orgs.edit.error' }));
						}
					);

					return;


				case USERS_FORM_FIELD_CHANGED:
					switch (action.payload.field) {
						case 'username':
							action.payload.value = action.payload.value.replace(/\W/, '');
							break;

						case 'password':
							action.payload.value = action.payload.value.replace(/[а-я\s]/gi, '');
							break;

						case 'passwordConfirm':
							action.payload.value = action.payload.value.replace(/[а-я\s]/gi, '');
							break;

						case 'name':
						case 'email':
						case 'phone':
							action.payload.value = action.payload.value.replace(/\s{2,}/g, ' ');
							break;
					}

					break;


				case USER_ADD:
					appFetch(
						state.app.backEnd + '/api/users',
						{
							credentials: 'include',
							method: 'POST',
							headers: { 'Content-Type': 'application/json' },
							body: JSON.stringify({
								org: action.payload.org,
								username: state.users.form.username,
								password: state.users.form.password,
								passwordConfirm: state.users.form.passwordConfirm,
								name: state.users.form.name,
								email: state.users.form.email,
								phone: state.users.form.phone,
								isSecretary: state.users.form.isSecretary,
								allowArchive: state.users.form.allowArchive,
								restrictedMeets: state.users.form.restrictedMeets
							})
						},
						(data) => {
							if (data.status === 'ok') {
								dispatch(showNotification({ severity: 'success', text: 'users.add.succeeded' }));
								dispatch(closeModal({ action: usersFormClear() }));
								dispatch(orgsLoad());

							} else if (data.status === 'error')
								dispatch(showNotification({ severity: 'error', text: 'users.add.error' }));
							else if (data.status === 'too_much_users' && data.limit)
								dispatch(showNotification({ severity: 'error', text: 'users.add.' + data.status, textAttr: { limit: data.limit } }));
							else
								dispatch(showNotification({ severity: 'error', text: 'users.add.' + data.status }));
						},
						dispatch,
						() => {
							dispatch(showNotification({ severity: 'error', text: 'users.add.error' }));
						}
					);

					return;


				case USER_DEL:
					appFetch(
						state.app.backEnd + '/api/users',
						{
							credentials: 'include',
							method: 'DELETE',
							headers: { 'Content-Type': 'application/json' },
							body: JSON.stringify({
								id: action.payload.id
							})
						},
						(data) => {
							if (data.status === 'ok') {
								dispatch(showNotification({ severity: 'success', text: 'users.del.succeeded' }));
								dispatch(closeConfirmDialog());
								dispatch(orgsLoad());
							} else
								dispatch(showNotification({ severity: 'error', text: 'users.del.error' }));
						},
						dispatch,
						() => {
							dispatch(showNotification({ severity: 'error', text: 'users.del.error' }));
						}
					)

					return;


				case USER_EDIT:
					appFetch(
						state.app.backEnd + '/api/users',
						{
							credentials: 'include',
							method: 'PUT',
							headers: { 'Content-Type': 'application/json' },
							body: JSON.stringify({
								id: action.payload.id,
								password: state.users.form.password,
								passwordConfirm: state.users.form.passwordConfirm,
								name: state.users.form.name,
								email: state.users.form.email,
								phone: state.users.form.phone,
								isSecretary: state.users.form.isSecretary,
								active: state.users.form.active,
								allowArchive: state.users.form.allowArchive,
								restrictedMeets: state.users.form.restrictedMeets
							})
						},
						(data) => {
							if (data.status === 'ok') {
								dispatch(showNotification({ severity: 'success', text: 'users.edit.succeeded' }));
								dispatch(closeModal({ action: usersFormClear() }));
								dispatch(orgsLoad());

							} else if (data.status === 'error')
								dispatch(showNotification({ severity: 'error', text: 'users.edit.error' }));

							else
								dispatch(showNotification({ severity: 'error', text: 'users.edit.' + data.status }));
						},
						dispatch,
						() => {
							dispatch(showNotification({ severity: 'error', text: 'users.edit.error' }));
						}
					);

					return;
			}

			next(action);
		}
	}
}
