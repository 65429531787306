import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { roomJoin, roomStartMobileIframe } from './actions';
import {
    Container,
    TextField,
    Button
} from '@mui/material';
import InputMask from 'react-input-mask';
import { isIOS } from 'react-device-detect';


class JoinRoom extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            roomId: props.roomId
        };
    }


    joinRoom( event ) {
        const { dispatch } = this.props;
        event.preventDefault();
        dispatch( roomJoin(this.state.roomId, this.props.hash) );
    }


    render() {
        const { roomId, hash, _room, _settings, dispatch, t } = this.props;
        const { info, status, limit } = _room;

        return (
            <Container maxWidth="sm">
                <form onSubmit={ event => { this.joinRoom(event) } }>
                    <InputMask
                        mask="99-99-99"
                        disabled={ Boolean(roomId) && Boolean(hash) }
                        value={ this.state.roomId }
                        onChange={
                            event => { 
                                this.setState({ roomId: event.target.value })
                            }
                        }
                    >
                        {
                            () =>
                                <TextField
                                    variant="outlined"
                                    size="small"
                                    margin="dense"
                                    fullWidth
                                    required
                                    label={ t('room.roomId') }
                                    name="roomId"
                                    disabled={ Boolean(roomId) && Boolean(hash) }
                                    error={ Boolean(status) }
                                    helperText={ Boolean(status) ? t(`room.${ status }`, { limit }) : '' }
                                />
                        }
                    </InputMask>

                    <br />
                    <br />

                    <Button variant="contained" type="submit">{ t('room.join') }</Button>
                </form>

                { Object.keys(info).length > 0 ?
                    <p>
                        { `${ t('meet.mobile.loadFrom') } ` }
                        {
                            isIOS
                            ?
                                <a href={ _settings.iosApp } target="_blank">
                                    App Store
                                </a>
                            :
                                <a href={ _settings.androidApp } target="_blank">
                                    Google Play
                                </a>
                        }

                        <br />
                        { `${ t('meet.mobile.message1') }? ` }
                        <a
                            href="#"
                            onClick={
                                event => {
                                    event.preventDefault();
                                    dispatch( roomStartMobileIframe() );
                                }
                            }
                        >
                            { t('meet.mobile.message2') }
                        </a>
                    </p>
                    :
                    null
                }
            </Container>
        )
    }
}


function mapStateToProps( state ) {
    return {
        _room: state.room,
        _settings: state.app.settings
    }
}


export default connect(mapStateToProps)(withTranslation()(JoinRoom));
