import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import {
    meetWebinarRegToggled,
    meetLobbyToggled,
    meetAutoLobbyToggled,
    meetAutoOwnerToggled,
    meetStartAudioMutedToggled,
    meetStartVideoMutedToggled,
    meetPassEnabledToggled,
    meetPassChanged,
    meetPeriodicToggled,
    meetDaysOfWeekChanged,
    meetAutoRecording
} from '../actions';
import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Typography,
    FormGroup,
    FormControlLabel,
    Checkbox
} from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
import Option from './Option';


const DOW = props => {
    const {
        day,
        disabled,
        checked,
        dispatch,
        t
    } = props;
    return (
        <FormControlLabel
            label={t(`meetForm.daysOfWeek.${day}`)}
            control={
                <Checkbox
                    disabled={disabled}
                    checked={checked}
                    onChange={() => { dispatch(meetDaysOfWeekChanged({ day })) }}
                    color="primary"
                />
            }
        />
    )
}


class Options extends React.Component {
    renderDOW() {
        const {
            isRooms,
            isCurrent,
            _form,
            dispatch,
            t
        } = this.props;

        if (!_form.periodic || isRooms)
            return null;

        return (
            <div className="option">
                {
                    [
                        'monday',
                        'tuestday',
                        'wednesday',
                        'thursday',
                        'friday',
                        'saturday',
                        'sunday'
                    ].map(day => {
                        return (
                            <DOW
                                key={day}
                                day={day}
                                disabled={isCurrent}
                                checked={_form.daysOfWeek[day]}
                                dispatch={dispatch}
                                t={t}
                            />
                        )
                    })
                }
            </div>
        )
    }


    render() {
        const {
            optionsExpanded,
            isRooms,
            _editId,
            isWebinars,
            isCurrent,
            _form,
            _settings,
            dispatch,
            t
        } = this.props;

        return (
            <Accordion defaultExpanded={optionsExpanded}>
                <AccordionSummary
                    expandIcon={<ExpandMore />}
                    aria-controls="options"
                    id="options"
                >
                    <Typography variant="body2">{t('meetsList.options')}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <FormGroup row>
                        <Option
                            name="webinarReg"
                            showable={isWebinars}
                            disabled={isCurrent}
                            checked={_form.webinarReg}
                            onChangeHandler={() => dispatch(meetWebinarRegToggled(!_form.webinarReg))}
                        />
                        <Option
                            name="lobby"
                            disabled={isCurrent}
                            checked={_form.lobby}
                            onChangeHandler={() => dispatch(meetLobbyToggled())}
                        />
                        <Option
                            name="autoLobby"
                            showable={_settings.autoLobby}
                            disabled={isCurrent}
                            checked={_form.autoLobby}
                            onChangeHandler={() => dispatch(meetAutoLobbyToggled())}
                        />
                        <Option
                            name="autoOwner"
                            showable={_settings.autoOwner && !isRooms}
                            disabled={isCurrent}
                            checked={_form.autoOwner}
                            onChangeHandler={() => dispatch(meetAutoOwnerToggled())}
                        />
                        <Option
                            name="startAudioMuted"
                            checked={_form.startAudioMuted}
                            onChangeHandler={() => dispatch(meetStartAudioMutedToggled())}
                        />
                        <Option
                            name="startVideoMuted"
                            checked={_form.startVideoMuted}
                            onChangeHandler={() => dispatch(meetStartVideoMutedToggled())}
                        />
                        <Option
                            name="passEnabled"
                            disabled={isCurrent}
                            checked={_form.passEnabled}
                            onChangeHandler={() => dispatch(meetPassEnabledToggled())}
                        />

                        <Option
                            name="pass"
                            showable={_form.passEnabled}
                            isSwitch={false}
                            disabled={isCurrent}
                            checked={_form.pass}
                            onChangeHandler={event => dispatch(meetPassChanged({ pass: event.target.value }))}
                        />
                        <Option
                            name="periodic"
                            showable={!(isRooms || _editId)}
                            disabled={isCurrent}
                            checked={_form.periodic}
                            onChangeHandler={() => dispatch(meetPeriodicToggled())}
                        />
                        {this.renderDOW()}
                        <Option
                            name="automatic"
                            showable={_settings.autoRecording}
                            disabled={isCurrent}
                            checked={_form.autoRecording}
                            onChangeHandler={() => dispatch(meetAutoRecording())}


                        />


                    </FormGroup>
                </AccordionDetails>
            </Accordion>
        )
    }
}


function mapStateToProps(state) {
    const editId = state.meets.editId;


    return {
        _editId: editId,
        _form: state.meets.form,
        _settings: state.app.settings
    }

}


export default connect(mapStateToProps)(withTranslation()(Options));
